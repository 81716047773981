import { SVGProps } from 'react';

export const TurnPhoneWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg width={72} height={76} viewBox="0 0 72 76" {...props} xmlns="http://www.w3.org/2000/svg">
    <g id="Frame 48097917">
      <path
        id="Vector"
        d="M66.665 38.6002H41.8411V43.9238L66.6859 43.9348L66.6738 69.8132C66.6738 69.8132 66.6713 69.8132 66.665 69.8132L41.8398 69.8034C41.8389 71.7512 41.2731 73.5538 40.3535 75.1271H66.665C69.6075 75.1271 72 72.7346 72 69.7925V43.9348C72 40.9951 69.6075 38.6002 66.665 38.6002Z"
      />
      <path
        id="Vector_2"
        d="M31.1934 15.4793H5.33468C2.39374 15.4793 0 17.873 0 20.814V69.7924C0 72.7346 2.39374 75.1271 5.33468 75.1271H17.6867H31.1934C34.1334 75.1271 36.5271 72.7346 36.5271 69.7924V20.814C36.5271 17.873 34.1334 15.4793 31.1934 15.4793ZM5.33468 20.792L31.2131 20.814L31.202 64.4748H5.31639L5.33468 20.792ZM15.4462 69.6516C15.4462 68.0967 16.7073 66.8355 18.2611 66.8355C19.8211 66.8355 21.0823 68.0966 21.0823 69.6516C21.0823 71.2079 19.8212 72.4715 18.2611 72.4715C16.7073 72.4714 15.4462 71.2079 15.4462 69.6516Z"
      />
      <path
        id="Vector_3"
        d="M54.0559 19.7586H50.5114C50.2541 19.7586 50.0238 19.9128 49.9248 20.1519C49.8281 20.3869 49.8819 20.6612 50.063 20.8412L56.2819 27.0612C56.5304 27.3075 56.9309 27.3075 57.1794 27.0612L63.3983 20.8412C63.5783 20.6612 63.6335 20.3869 63.5367 20.1519C63.4386 19.9128 63.2072 19.7586 62.9501 19.7586H59.3688C59.168 8.83189 50.2308 0 39.2576 0C37.7907 0 36.6019 1.1888 36.6019 2.65573C36.6019 4.12405 37.7907 5.31408 39.2576 5.31408C47.3021 5.31408 53.8564 11.7607 54.0559 19.7586Z"
      />
    </g>
  </svg>
);
