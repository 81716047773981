import {
  EditMachineVendistaSettingDTO,
  SignInVendistaDTO,
} from '../../types/serverInterface/vendistaDTO';
import { AppDispatch } from '../../app/store';
import {
  checkVendistaTokenThunk,
  deleteVendistaSettingThunk,
  editMachineVendistaSettingThunk,
  getMachineVendistaSettingListByOrganizationIdThunk,
  getMachineVendistaSettingThunk,
  signInVendistaThunk,
} from './thunk';

/**
 * Авторизация Vendista
 *
 * @param data данные формы авторизации
 */
export const signInVendistaAction = (data: SignInVendistaDTO) => (dispatch: AppDispatch) =>
  dispatch(signInVendistaThunk(data)).then(() => {
    dispatch(checkVendistaTokenAction(data.organizationId));
  });

/**
 * Получение информации о состоянии токена vendista
 *
 * @param organizationId id организации
 */
export const checkVendistaTokenAction = (organizationId: number) => (dispatch: AppDispatch) =>
  dispatch(checkVendistaTokenThunk(organizationId));

/**
 * Удаление интеграции Vendista организации
 *
 * @param organizationId id организации
 */
export const deleteVendistaSettingAction = (organizationId: number) => (dispatch: AppDispatch) =>
  dispatch(deleteVendistaSettingThunk(organizationId));

/**
 * Получение номера терминала vendista автомата
 *
 * @param organizationId id организации
 * @param machineId id автомата
 */
export const getMachineVendistaSettingAction =
  (organizationId: number, machineId: number) => (dispatch: AppDispatch) =>
    dispatch(getMachineVendistaSettingThunk({ organizationId, machineId }));

/**
 * Получение списка автоматов с терминалом vendista организации
 *
 * @param organizationId id организации
 */
export const getMachineVendistaSettingListByOrganizationIdAction =
  (organizationId: number) => (dispatch: AppDispatch) =>
    dispatch(getMachineVendistaSettingListByOrganizationIdThunk(organizationId));

/**
 * Изменения номера терминала vendista автомата
 *
 * @param organizationId id организации
 * @param machineId id автомата
 * @param data данные формы изменения настроек vendista автомата
 */
export const editMachineVendistaSettingAction =
  (organizationId: number, machineId: number, data: EditMachineVendistaSettingDTO) =>
  (dispatch: AppDispatch) =>
    dispatch(editMachineVendistaSettingThunk({ organizationId, machineId, data })).then(() => {
      dispatch(getMachineVendistaSettingAction(organizationId, machineId));
    });
