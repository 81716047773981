import { MachinePricesInfoRes } from '../../../../types/serverInterface/machineDTO';
import { PriceFieldError, PriceMapError, SnackCellPriceWithCellId } from './types';
import { initialFieldError } from '../../../../helpers/validateHelpers';
import {
  EditSnackMachineStoragePrice,
  EditSnackMachineStorageSnackCell,
} from '../../../../types/serverInterface/snackMachineStorage/editSnackMachineStorage';
import { GetSnackMachineStorageDTO } from '../../../../types/serverInterface/snackMachineStorage/getSnackMachineStorage';

/**
 * Получение списка товаров и цен для снек автомата
 *
 * @param snackMachineStorage склад снек автомата
 */
export const getSnackProductsWithPrices = (
  snackMachineStorage: GetSnackMachineStorageDTO | null,
): Record<number, SnackCellPriceWithCellId> => {
  const priceMap: Record<number, SnackCellPriceWithCellId> = {};

  snackMachineStorage?.snackCells?.forEach((cell) => {
    const price = cell.price;

    if (price && price.productId) {
      priceMap[price.productId] = {
        cellId: cell.id,
        price: price.price,
      };
    }
  });

  return priceMap;
};

// /**
//  * Преобразование данных для post запроса
//  *
//  * @param fillingForm форма с наполнением снек автомата
//  * @param pricesMap список товаров и цен для снек автомата
//  */
// export const createEditMachinePriceArray = (
//   fillingForm: EditSnackMachineStorageSnackCell[][],
//   pricesMap: Record<number, SnackCellPriceWithCellId>,
// ): EditSnackMachineStoragePrice[] => {
//   const editMachinePrices: EditSnackMachineStoragePrice[] = [];
//
//   fillingForm.forEach((row) => {
//     row.forEach((cell) => {
//       const productId = cell.snackProductId || null;
//
//       if (productId !== null && pricesMap[productId]) {
//         const priceInfo = pricesMap[productId];
//
//         const editMachinePrice: EditSnackMachineStoragePrice = {
//           productId: productId,
//           volume: cell?.maxVolume || 0,
//           price: priceInfo.price,
//         };
//
//         editMachinePrices.push(editMachinePrice);
//       }
//     });
//   });
//
//   return editMachinePrices;
// };

/**
 * Валидация формы изменения цены
 *
 * @param ProductCellPriceMap словарь по productId со значением cellId и priceId
 * @param fillingForm форма наполнения снек автомата
 * @param setPriceMapErrors метод перезаписи ошибок
 */
export const validatePriceMap = (
  ProductCellPriceMap: Record<number, SnackCellPriceWithCellId>,
  fillingForm: EditSnackMachineStorageSnackCell[][],
  setPriceMapErrors: (error: PriceMapError) => void,
): boolean => {
  let isValid = true;
  const priceMapErrors: PriceMapError = {};

  fillingForm.flat().forEach((cell) => {
    const productId = cell.snackProductId;

    if (productId) {
      const cell = ProductCellPriceMap[productId];
      let error: PriceFieldError = initialFieldError;

      if (cell && cell.price === 0) {
        error = {
          isError: true,
          label: 'machineControl.machine.snackFilling.table.content.warning.priceMoreThanZero',
          status: 'warning',
        };
        isValid = false;
      }

      if (cell && cell.price && cell.price < 0) {
        error = {
          isError: true,
          label: 'machineControl.machine.snackFilling.table.content.alert.negativePrice',
          status: 'alert',
        };
        isValid = false;
      }

      if (!cell || cell.price === null) {
        error = {
          isError: true,
          label: 'machineControl.machine.snackFilling.table.content.alert.noPrice',
          status: 'alert',
        };
        isValid = false;
      }

      priceMapErrors[productId] = error;
    }
  });

  setPriceMapErrors(priceMapErrors);
  return isValid;
};
