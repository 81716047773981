import { RootState } from '../../app/store';

/**
 * Получение списка моделей автомата
 */
export const selectMachineModelList = () => (state: RootState) => state.machineControl.modelList;

/**
 * Получение детальной информации о модели автомата
 */
export const selectMachineModelDetails = () => (state: RootState) =>
  state.machineControl.modelDetails;

/**
 * Получение списка автоматов текущей организации
 */
export const selectMachineList = () => (state: RootState) => state.machineControl.machineList;

/**
 * Получение маленького списка автоматов
 */
export const selectSmallMachineList = () => (state: RootState) =>
  state.machineControl.machineSmallList;

/**
 * Селектор получение списка серийных номеров автомата
 */
export const selectMachineSerialNumberList = () => (state: RootState) =>
  state.machineControl.machineSerialNumberList;

/**
 * Получение информации и товарах автомата
 */
export const selectMachinePrices = () => (state: RootState) => state.machineControl.machinePrices;

/**
 * Получение базовой информации об автомате
 */
export const selectMachineBaseInfo = () => (state: RootState) => state.machineControl.machineBase;

/**
 * Получение информации для домашней страницы автомата
 */
export const selectMachineHomePageInfo = () => (state: RootState) =>
  state.machineControl.machineHome;

/**
 * Получение списка логов автомата
 */
export const selectMachineLogList = () => (state: RootState) => state.machineControl.machineLogs;

/**
 * Получение информации для страницы склада автомата
 */
export const selectMachineStorageInfo = () => (state: RootState) =>
  state.machineControl.machineStorageInfo;

/**
 * Получение информации для страницы цен автомата
 */
export const selectMachinePricesInfo = () => (state: RootState) =>
  state.machineControl.machinePricesInfo;

/**
 * Селектор получения обновлённого склада автомата
 */
export const selectNewStorage = () => (state: RootState) =>
  state.machineControl.machineNewStorageInfo;

/**
 * Селектор получения списка продаж автомата
 */
export const selectMachineSales = () => (state: RootState) => state.machineControl.machineSales;

/**
 * Селектор получения количества продаж автомата
 */
export const selectMachineSalesQty = () => (state: RootState) =>
  state.machineControl.machineSalesQty;

/**
 * Селектор получения настроек автомата
 */
export const selectMachineSettings = () => (state: RootState) =>
  state.machineControl.machineSettings;

/**
 * Селектор получения ключа регистрации автомата в системе
 */
export const selectMachineRegistrationCode = () => (state: RootState) =>
  state.machineControl.registrationCode;

/**
 * Селектор получения калибровки ингредиента
 */
export const selectMachineIngredientCalibration = () => (state: RootState) =>
  state.machineControl.ingredientCalibrationValue;

/**
 * Селектор получения списка торговых точек
 */
export const selectOutletList = () => (state: RootState) => state.machineControl.outletList;

/**
 * Селектор получение фильтров списка торговых точек
 */
export const selectOutletFilters = () => (state: RootState) => state.machineControl.outletFilters;

/**
 * Селектор получения списка id автоматов торговой точки
 */
export const selectOutletMachineIds = () => (state: RootState) =>
  state.machineControl.outletMachineIds;

/**
 * Селектор получения даты последней продажи автомата
 */
export const selectMachineSaleInfoLastDate = () => (state: RootState) =>
  state.machineControl.machineSaleInfoLastDate;

/**
 * Селектор получения количества продаж автомата за различные периоды
 */
export const selectMachineSaleInfoQty = () => (state: RootState) =>
  state.machineControl.machineSaleInfoQty;

/**
 * Селектор получения склада снек автомата
 */
export const selectSnackMachineStorage = () => (state: RootState) =>
  state.machineControl.snackMachineStorage;
