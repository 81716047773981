import { AbstractApiModule } from '../../abstractApiModule';
import {
  CheckVendistaTokenDTO,
  EditMachineVendistaSettingDTO,
  MachineVendistaSettingDTO,
  MachineVendistaSettingListItem,
  SignInVendistaDTO,
} from '../../../../types/serverInterface/vendistaDTO';
import { vendistaBaseUrl } from '../../../../consts';

export class VendistaModule extends AbstractApiModule {
  /**
   * Авторизация Vendista
   *
   * @param data данные формы авторизации
   */
  signInVendista(data: SignInVendistaDTO) {
    return this.request.post<SignInVendistaDTO, void>(
      `${vendistaBaseUrl}/vendista-settings/signInVendista`,
      data,
    );
  }

  /**
   * Получение информации о состоянии токена vendista
   *
   * @param organizationId id организации
   */
  checkVendistaToken(organizationId: number) {
    //return getDataFromServer(checkVendistaTokenMock);
    return this.request.get<void, CheckVendistaTokenDTO>(
      `${vendistaBaseUrl}/vendista-settings/${organizationId}/check-token`,
    );
  }

  /**
   * Удаление интеграции Vendista организации
   *
   * @param organizationId id организации
   */
  deleteVendistaSetting(organizationId: number) {
    return this.request.delete(`${vendistaBaseUrl}/vendista-settings/${organizationId}`);
  }

  /**
   * Получение номера терминала vendista автомата
   *
   * @param organizationId id организации
   * @param machineId id автомата
   */
  getMachineVendistaSetting(
    organizationId: number,
    machineId: number,
  ): Promise<MachineVendistaSettingDTO> {
    return this.request.get(
      `${vendistaBaseUrl}/machine-vendista-settings/${organizationId}/${machineId}`,
    );
  }

  /**
   * Получение списка автоматов с терминалом vendista организации
   *
   * @param organizationId id организации
   */
  getMachineVendistaSettingListByOrganizationId(organizationId: number) {
    return this.request.get<void, MachineVendistaSettingListItem[]>(
      `${vendistaBaseUrl}/machine-vendista-settings/${organizationId}/machineList`,
    );
  }

  /**
   * Изменения номера терминала vendista автомата
   *
   * @param organizationId id организации
   * @param machineId id автомата
   * @param data данные формы изменения настроек vendista автомата
   */
  editMachineVendistaSetting(
    organizationId: number,
    machineId: number,
    data: EditMachineVendistaSettingDTO,
  ) {
    return this.request.put<EditMachineVendistaSettingDTO, number>(
      `${vendistaBaseUrl}/machine-vendista-settings/${organizationId}/${machineId}`,
      data,
    );
  }
}
