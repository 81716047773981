import { FC } from 'react';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { useFormik } from 'formik';
import { vendistaSignInFormValidationScheme } from './vendistaSignInFormValidationScheme';
import { TextField } from '@consta/uikit/TextField';
import DefaultModal from '../../../../../components/DefaultModal';
import { useAppDispatch } from '../../../../../app/hooks/store';
import styles from './VendistaSignInForm.module.scss';
import { VendistaSignInFormProps } from './types';
import { initialFormState } from './consts';
import { IconLogoFull } from '../../../../../assets/icon/iconLogoFull';
import { defaultIconProps } from '../../../../../consts/defaultIconProps';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import { IconLogoVendistaFull } from '../../../../../assets/icon/iconLogoVendistaFull';
import { IconSwap } from '../../../../../assets/icon/iconSwap';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { useTranslation } from 'react-i18next';
import { SignInVendistaDTO } from '../../../../../types/serverInterface/vendistaDTO';
import { signInVendistaAction } from '../../../../../state/vendista/action';

/**
 * Форма входа в Vendista
 */
const VendistaSignInForm: FC<VendistaSignInFormProps> = ({ organizationId, onClose }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const formik = useFormik({
    validationSchema: vendistaSignInFormValidationScheme,
    initialValues: initialFormState,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  // Обработчики
  function handleSubmit(data: SignInVendistaDTO) {
    dispatch(signInVendistaAction({ ...data, organizationId })).then(() => {
      onClose();
    });
  }

  // render методы
  const renderActions = () => (
    <>
      <Button
        size="l"
        view="clear"
        label={t('integration.vendista.signIn.back.button.label')}
        onClick={onClose}
      />
      <Button
        size="l"
        label={t('integration.vendista.signIn.signIn.button.label')}
        onClick={() => formik.handleSubmit()}
      />
    </>
  );

  const renderModalHeader = () => (
    <HorizontalContainer space="xs" justify="center">
      <IconLogoFull className={styles.logoShaker} {...defaultIconProps} />
      <VerticalContainer justify="center">
        <IconSwap className={styles.swapIcon} {...defaultIconProps} />
      </VerticalContainer>
      <IconLogoVendistaFull className={styles.logoVendista} {...defaultIconProps} />
    </HorizontalContainer>
  );

  const renderModalTitle = () => (
    <HorizontalContainer className={styles.modalTitle} justify="center">
      <Text size="xl" weight="semibold">
        {t('integration.vendista.signIn.modal.title')}
      </Text>
    </HorizontalContainer>
  );

  const renderTextFields = () => (
    <VerticalContainer space="l">
      <TextField
        size="l"
        label={t('integration.vendista.signIn.login.input.label')}
        name="login"
        width="full"
        placeholder={t('integration.vendista.signIn.login.input.placeholder')}
        required
        value={formik.values.login}
        status={formik.errors.login ? 'alert' : undefined}
        caption={formik.errors.login}
        onChange={({ e }) => formik.handleChange(e)}
      />
      <TextField
        type="password"
        size="l"
        label={t('integration.vendista.signIn.password.input.label')}
        name="password"
        width="full"
        placeholder={t('integration.vendista.signIn.password.input.placeholder')}
        required
        value={formik.values.password}
        status={formik.errors.password ? 'alert' : undefined}
        caption={formik.errors.password}
        onChange={({ e }) => formik.handleChange(e)}
      />
    </VerticalContainer>
  );

  return (
    <DefaultModal
      withHeader={false}
      onClose={onClose}
      renderActions={renderActions}
      className={styles.VendistaSignInForm}
    >
      <VerticalContainer space="3xl">
        {renderModalHeader()}
        {renderModalTitle()}
        {renderTextFields()}
      </VerticalContainer>
    </DefaultModal>
  );
};

export default VendistaSignInForm;
