import React, { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import {
  selectMachineSales,
  selectMachineSalesQty,
} from '../../../../state/machineControl/selectors';
import {
  getMachineSaLesListAction,
  getMachineSaLesListQtyAction,
} from '../../../../state/machineControl/actions';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { MachineSalesDTO, SaleMethod } from '../../../../types/serverInterface/machineDTO';
import VerticalContainer from '../../../../components/VerticalContainer';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import { IconSaleMethodCard } from '../../../../assets/icon/iconSaleMethodCard';
import { IconSaleMethodQrCode } from '../../../../assets/icon/iconSaleMethodQrCode';
import { IconSaleMethodCash } from '../../../../assets/icon/iconSaleMethodCash';
import { IconSaleMethodRfId } from '../../../../assets/icon/iconSaleMethodRfId';
import { defaultIconProps } from '../../../../consts/defaultIconProps';
import { TypeDiscountEnum } from '../../../../types/serverInterface/promoCodeDTO';
import styles from './MachineSales.module.scss';
import { withTooltip } from '@consta/uikit/withTooltip';
import TableHeaderButton from '../../../../components/ClassicTable/ClassicTableHeader/TableHeaderButton';
import { IconDocFilled } from '../../../../assets/icon/iconDocFilled';
import { useExportSalesFromExcel } from './helpers/useExportSalesFromExcel';
import TableCardWithBackground from '../../../../components/TableCardWithBackground';
import { useTranslation } from 'react-i18next';
import { MachineSalesProps } from './types';

const TextWithTooltip = withTooltip({ direction: 'downCenter' })(Text);

/**
 * Список продаж автомата
 */
const MachineSales: FC<MachineSalesProps> = ({ serialNumber }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { state, isLoading } = useAppSelector(selectMachineSales());
  const fullLength = useAppSelector(selectMachineSalesQty());
  const { handleExcelExportClick } = useExportSalesFromExcel({
    serialNumber,
    page: 0,
    limit: fullLength,
  });

  // const [pageLength, setPageLength] = useState<number>(20);
  // const [startPageIndex, setStartPageIndex] = useState<number>(0);

  const salesList = state?.data;
  const { page, limit } = state?.pagination || { page: 1, limit: 10 };

  useEffect(() => {
    serialNumber && dispatch(getMachineSaLesListAction(serialNumber, page, limit));
  }, [dispatch, serialNumber, page, limit]);

  useEffect(() => {
    serialNumber && dispatch(getMachineSaLesListQtyAction(serialNumber));
  }, [dispatch, serialNumber]);

  // Обработчики
  const handleBackClick = () => {
    !isLoading &&
      serialNumber &&
      dispatch(getMachineSaLesListAction(serialNumber, page - 1, limit));
  };

  const handleNextClick = () => {
    !isLoading &&
      serialNumber &&
      dispatch(getMachineSaLesListAction(serialNumber, page + 1, limit));
  };

  const handleLimitChange = (limit: number) => {
    !isLoading && serialNumber && dispatch(getMachineSaLesListAction(serialNumber, page, limit));
  };

  // render методы
  const renderNameCell = (sale: MachineSalesDTO) => (
    <VerticalContainer space={0}>
      <Text size="m">{sale.name}</Text>
      <Text size="s" view="ghost">
        {sale.volume}
        {sale.unit === 'ML'
          ? t('machineControl.machine.sales.nameCell.type.ml')
          : t('machineControl.machine.sales.nameCell.type.pcs')}
      </Text>
    </VerticalContainer>
  );

  const renderPriceCell = (sale: MachineSalesDTO) => {
    const { price, discountPrices } = sale;
    const discountPrice = discountPrices?.reduce((acc, price) => {
      return acc + price.price;
    }, 0 as number);

    return (
      <VerticalContainer space="3xs">
        <HorizontalContainer space="xs" align="center">
          <TextWithTooltip
            tooltipProps={{
              content: (
                <HorizontalContainer align="center">
                  {discountPrices?.map(({ method, price }, index) => {
                    switch (method) {
                      case SaleMethod.CARD:
                        return (
                          <HorizontalContainer key={index} space="2xs">
                            <Text size="m">{price}</Text>
                            <IconSaleMethodCard size="s" {...defaultIconProps} />
                          </HorizontalContainer>
                        );
                      case SaleMethod.RF_ID:
                        return (
                          <HorizontalContainer key={index} space="2xs">
                            <Text size="m">{price}</Text>
                            <IconSaleMethodRfId size="s" {...defaultIconProps} />
                          </HorizontalContainer>
                        );
                      case SaleMethod.CASH:
                        return (
                          <HorizontalContainer key={index} space="2xs">
                            <Text size="m">{price}</Text>
                            <IconSaleMethodCash size="s" {...defaultIconProps} />
                          </HorizontalContainer>
                        );
                      case SaleMethod.QR_CODE:
                        return (
                          <HorizontalContainer key={index} space="2xs">
                            <Text size="m">{price}</Text>
                            <IconSaleMethodQrCode size="s" {...defaultIconProps} />
                          </HorizontalContainer>
                        );
                    }
                  })}
                </HorizontalContainer>
              ) as any,
            }}
          >
            {discountPrice || 0}
          </TextWithTooltip>
          {discountPrices?.map(({ method }, index) => {
            switch (method) {
              case SaleMethod.CARD:
                return (
                  <IconSaleMethodCard
                    className={styles.iconSize}
                    size="m"
                    key={index}
                    {...defaultIconProps}
                  />
                );
              case SaleMethod.RF_ID:
                return (
                  <IconSaleMethodRfId
                    className={styles.iconSize}
                    size="m"
                    key={index}
                    {...defaultIconProps}
                  />
                );
              case SaleMethod.CASH:
                return (
                  <IconSaleMethodCash
                    className={styles.iconSize}
                    size="m"
                    key={index}
                    {...defaultIconProps}
                  />
                );
              case SaleMethod.QR_CODE:
                return (
                  <IconSaleMethodQrCode
                    className={styles.iconSize}
                    size="m"
                    key={index}
                    {...defaultIconProps}
                  />
                );
            }
          })}
        </HorizontalContainer>
        {discountPrice !== price && (
          <Text size="s" className={styles.lineThrough} view="ghost">
            {price}
          </Text>
        )}
      </VerticalContainer>
    );
  };

  const renderPromoCodeCell = (sale: MachineSalesDTO) => (
    <VerticalContainer space={0}>
      <Text size="m">{sale.promoCode?.code}</Text>
      <Text
        size="s"
        view={sale.promoCode?.amount ? 'ghost' : 'primary'}
      >{`${sale.promoCode?.type !== TypeDiscountEnum.FREE ? sale.promoCode?.amount || '' : ''} ${
        sale.promoCode?.type === TypeDiscountEnum.PERCENT
          ? t('machineControl.machine.sales.promoCode.type.percent')
          : sale.promoCode?.type === TypeDiscountEnum.FIXED
            ? t('machineControl.machine.sales.promoCode.type.fixed')
            : sale.promoCode?.type === TypeDiscountEnum.FREE
              ? t('machineControl.machine.sales.promoCode.type.free')
              : t('machineControl.machine.sales.promoCode.type.noPromoCode')
      }`}</Text>
    </VerticalContainer>
  );

  const renderDateSaleCell = (sale: MachineSalesDTO) => (
    <VerticalContainer space={0}>
      <Text size="m">{sale.dateSale.split(' ')[0]}</Text>
      <Text size="s" view="ghost">
        {sale.dateSale.split(' ')[1]}
      </Text>
    </VerticalContainer>
  );

  const renderTableHeaderActions = () => (
    <HorizontalContainer space="xs">
      <TableHeaderButton
        size="s"
        label={t('machineControl.machine.sales.header.importExcel.button.title')}
        disabled={false}
        iconLeft={IconDocFilled as any}
        onClick={handleExcelExportClick}
      />
    </HorizontalContainer>
  );

  // if (!salesList) return null;

  return (
    <div className={styles.machineSales}>
      <TableCardWithBackground
        // Для пагинации
        withPageSetting
        fullLength={fullLength}
        limit={limit}
        page={page}
        onBackClick={handleBackClick}
        onNextClick={handleNextClick}
        onLimitChange={handleLimitChange}
        // Не пагинация
        withHeaderActions
        withHeader
        renderTableHeadActions={renderTableHeaderActions}
        rows={salesList || []}
        columns={{
          name: {
            key: 'name',
            alignment: 'left',
            title: t('machineControl.machine.sales.product.column.title'),
            className: styles.nameCell,
            renderCell: renderNameCell,
          },
          price: {
            key: 'price',
            alignment: 'left',
            title: t('machineControl.machine.sales.price.column.title'),
            className: styles.priceCell,
            renderCell: renderPriceCell,
          },
          promoCode: {
            key: 'promoCode',
            alignment: 'left',
            title: t('machineControl.machine.sales.promoCode.column.title'),
            className: styles.promoCodeCell,
            renderCell: renderPromoCodeCell,
          },
          dateSale: {
            key: 'dateSale',
            alignment: 'right',
            title: t('machineControl.machine.sales.purchaseTime.column.title'),
            className: styles.dateSaleCell,
            renderCell: renderDateSaleCell,
          },
        }}
      />
    </div>
  );
};

export default MachineSales;
