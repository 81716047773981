/**
 * Адрес, где развёрнут фронт
 */
export const baseUrlFront = process.env.REACT_APP_URL_FRONT;

/**
 * Базовый url Keycloak
 */
export const authKKBaseUrl = process.env.REACT_APP_URL_AUTH;

/**
 * Базовый url модуля организации
 */
export const organizationBaseUrl = `${process.env.REACT_APP_BASE_URL}telemetry-organization`;

/**
 * Базовый url модуля "Управление автоматами"
 */
// export const machineControlBaseUrl = `http://localhost:8310`;
export const machineControlBaseUrl = `${process.env.REACT_APP_BASE_URL}telemetry-machine-control`;

/**
 * Базовый url модуля "База продуктов"
 */
export const productBaseUrl = `${process.env.REACT_APP_BASE_URL}telemetry-product-base`;

/**
 * Базовый url модуля "Продажи"
 */
export const salesBaseUrl = `${process.env.REACT_APP_BASE_URL}telemetry-sale`;
// export const salesBaseUrl = `http://localhost:8325`;

/**
 * Базовый url модуля "Уведомления"
 */
export const notificationBaseUrl = `${process.env.REACT_APP_BASE_URL}telemetry-notification`;
// export const notificationBaseUrl = 'http://localhost:8080';

/**
 * Базовый url модуля "Vendista"
 */
// export const vendistaBaseUrl = `http://localhost:8380`;
export const vendistaBaseUrl = `${process.env.REACT_APP_BASE_URL}telemetry-vendista-integration`;
