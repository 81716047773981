import { SignInVendistaDTO } from '../../../../../types/serverInterface/vendistaDTO';

/**
 * Начальное состояние формы VendistaSignInForm
 */
export const initialFormState: SignInVendistaDTO = {
  login: '',
  password: '',
  organizationId: 0,
};
