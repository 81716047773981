import { PromoCodeListFiltersReq } from '../../../../types/serverInterface/promoCodeDTO';
import { Pagination } from '../../../../types/types';
import { getPromoCodeListByExportAction } from '../../../../state/promoCode/actions';
import { useAppDispatch } from '../../../../app/hooks/store';
import { addFrontNotification } from '../../../../state/frontNotification/slice';

export const useExportPromoCodeFromClickBoard = ({
  organizationId,
  pagination,
  filters,
}: {
  organizationId: number;
  filters: PromoCodeListFiltersReq;
  pagination: Pagination;
  t: (key: string) => string;
}) => {
  const dispatch = useAppDispatch();

  return {
    handleExportFromClickBoardClick: () => {
      dispatch(getPromoCodeListByExportAction(organizationId, filters, pagination)).then((res) => {
        const promoCodeList = res.data;

        const text = promoCodeList && promoCodeList.map(({ code }) => code).join(', ');

        text &&
          navigator.clipboard.writeText(text).then(
            function () {
              dispatch(
                addFrontNotification({
                  status: 'success',
                  text: 'promoCode.list.notifications.textCopied.label',
                }),
              );
            },
            function () {
              dispatch(
                addFrontNotification({
                  status: 'alert',
                  text: 'promoCode.list.notifications.textCopiedError.label',
                }),
              );
            },
          );
      });
    },
  };
};
