import { TelegramNotificationSettingDTO } from '../../types/serverInterface/notificationDTO';
import { errorHandler, NotificationType } from '../handlers';
import { createSlice, isRejected } from '@reduxjs/toolkit';
import { getTelegramNotificationSettingsByOrganizationIdThunk } from './thunk';

type StateItemType<T> = {
  state: T extends [] ? T : T | null;
  isLoading: boolean;
  isReject: boolean;
};

export type TelemetryNotificationState = {
  telegramNotificationSettingsList: StateItemType<TelegramNotificationSettingDTO[]>;
  notifications: NotificationType[];
};

const initialState: TelemetryNotificationState = {
  telegramNotificationSettingsList: {
    state: [],
    isLoading: false,
    isReject: false,
  },
  notifications: [],
};

/**
 * Добавление уведомления
 *
 * @param state состояние
 * @param notification новое уведомление
 */
const addNotification = (state: TelemetryNotificationState) => (notification: NotificationType) => {
  const arr = [...state.notifications];
  arr.push(notification);

  state.notifications = arr;
};

export const telemetryNotificationSlice = createSlice({
  name: 'telemetryNotification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getTelegramNotificationSettingsByOrganizationIdThunk
    builder.addCase(getTelegramNotificationSettingsByOrganizationIdThunk.pending, (state) => {
      state.telegramNotificationSettingsList.isLoading = true;
      state.telegramNotificationSettingsList.isReject = false;
    });

    builder.addCase(getTelegramNotificationSettingsByOrganizationIdThunk.rejected, (state) => {
      state.telegramNotificationSettingsList.isLoading = false;
      state.telegramNotificationSettingsList.isReject = true;
    });

    builder.addCase(
      getTelegramNotificationSettingsByOrganizationIdThunk.fulfilled,
      (state, action) => {
        state.telegramNotificationSettingsList.isLoading = false;
        state.telegramNotificationSettingsList.state = action.payload;
      },
    );

    // Это глобальный обработчик ошибок телеметрии
    builder.addMatcher(isRejected(), (state, action) => {
      errorHandler(action)(addNotification(state));
    });
  },
});

export const telemetryNotificationReducer = telemetryNotificationSlice.reducer;
