import { InformerPropStatus } from '@consta/uikit/__internal__/src/components/Informer/Informer';

/**
 * Тип уведомления
 */
export type NotificationType = {
  /**
   * Описание
   */
  text: string | undefined;
  /**
   * Статус
   */
  status: InformerPropStatus;
};

/**
 * Обработчик ошибок
 *
 * @param res ошибка
 */
export const errorHandler =
  (res: { error: { message?: string } }) =>
  (addNotification: (notification: NotificationType) => void) => {
    addNotification({
      text: 'error.server.' + res.error?.message,
      status: 'alert',
    });
  };
