import { useEffect, useState } from 'react';
import {
  getMachineListByOrganizationIdAction,
  getMachineModelListAction,
  getOutletListAction,
} from '../../../../state/machineControl/actions';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import {
  selectMachineModelList,
  selectMachineSerialNumberList,
  selectOutletList,
} from '../../../../state/machineControl/selectors';
import {
  getBrandListAction,
  getCellCategoryListAction,
  getCellPurposeListAction,
  getCellViewListAction,
  getProductLineListAction,
  getProductListAction,
  getTasteListAction,
} from '../../../../state/productBase/actions';
import {
  selectBrandList,
  selectCellCategoryList,
  selectCellPurposeList,
  selectCellViewList,
  selectTasteList,
} from '../../../../state/productBase/selectors';
import { ProductListItemDTO } from '../../../../types/serverInterface/productDTO';
import { ProductLineDTO } from '../../../../types/serverInterface/productLineDTO';
import { selectCheckedVendistaToken } from '../../../../state/vendista/selectors';
import { checkVendistaTokenAction } from '../../../../state/vendista/action';

/**
 * hoc получения всех необходимых данных для страницы фильтров продаж организации
 *
 * @param organizationId id организации
 * @param brandIds список id брендов
 * @param productLineIds список id линеек продуктов
 */
export const useOrganizationSaleFilters = (
  organizationId: number | null,
  brandIds: number[] | null,
  productLineIds: number[] | null,
) => {
  const dispatch = useAppDispatch();

  const { state: modelList } = useAppSelector(selectMachineModelList());
  const { state: outletList } = useAppSelector(selectOutletList());
  const { state: machineList } = useAppSelector(selectMachineSerialNumberList());

  const brandList = useAppSelector(selectBrandList());
  const cellCategoryList = useAppSelector(selectCellCategoryList());
  const cellViewList = useAppSelector(selectCellViewList());
  const cellPurposeList = useAppSelector(selectCellPurposeList());
  const tasteList = useAppSelector(selectTasteList());

  const { state: checkedVendistaToken } = useAppSelector(selectCheckedVendistaToken());

  const [productLineList, setProductLineList] = useState<ProductLineDTO[]>([]);
  const [productList, setProductList] = useState<ProductListItemDTO[]>([]);

  useEffect(() => {
    dispatch(getMachineModelListAction());
    dispatch(getTasteListAction());
    dispatch(getCellCategoryListAction());
    dispatch(getCellViewListAction());
    dispatch(getCellPurposeListAction());
  }, [dispatch]);

  useEffect(() => {
    if (organizationId) {
      dispatch(getOutletListAction(organizationId, {}));
      dispatch(getMachineListByOrganizationIdAction(organizationId));
      dispatch(checkVendistaTokenAction(organizationId));
      dispatch(
        getBrandListAction({
          organizationId,
        }),
      );
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    if (organizationId && brandIds && brandIds.length > 0) {
      Promise.all(
        brandIds.map((brandId) => dispatch(getProductLineListAction(brandId, { organizationId }))),
      ).then((results) => {
        const combinedProductLines = results.map((res) => res.payload as ProductLineDTO[]).flat();
        setProductLineList(combinedProductLines);
      });
    }
  }, [dispatch, organizationId, brandIds]);

  useEffect(() => {
    if (organizationId && productLineIds && productLineIds.length > 0) {
      Promise.all(
        productLineIds.map((productLineId) =>
          dispatch(getProductListAction(productLineId, { organizationId })),
        ),
      ).then((results) => {
        const combinedProducts = results.map((res) => res.payload as ProductListItemDTO[]).flat();
        setProductList(combinedProducts);
      });
    }
  }, [dispatch, organizationId, productLineIds]);

  return {
    modelList,
    outletList,
    machineList,
    brandList,
    cellCategoryList,
    tasteList,
    cellViewList,
    cellPurposeList,
    productLineList,
    productList,
    checkedVendistaToken,
  };
};
