import { enumToArray } from '../../../types/enums';
import {
  ActivePromoCodeFilterEnum,
  DiscountTypePromoCodeFilterEnum,
  SelectedPromoCodeFilterEnum,
  UsagePromoCodeEnum,
} from './types';

/**
 * Список фильтров избранных промокодов
 */
export const selectedPromoCodeFiltersList = enumToArray(SelectedPromoCodeFilterEnum);

/**
 * Список фильтров активных промокодов
 */
export const activePromoCodeFiltersList = enumToArray(ActivePromoCodeFilterEnum);

/**
 * Список фильтров типов скидок промокодов
 */
export const discountTypePromoCodeFiltersList = enumToArray(DiscountTypePromoCodeFilterEnum);

/**
 * Список вариантов использования промокодов
 */
export const usageTypePromoCodeList = enumToArray(UsagePromoCodeEnum);
