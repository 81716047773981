import { Sidebar } from '@consta/uikit/Sidebar';
import { FC, useMemo } from 'react';
import { MobileLeftMenuProps } from './types';
import { useAppSelector } from '../../app/hooks/store';
import { selectOrganizationId } from '../../state/organization/selectors';
import { selectRole } from '../../state/user/selectors';
import { leftMenuGroupList } from '../LeftMenu/consts';
import { Roles } from '../../types/serverInterface/cabinetDTO';
import LeftMenuItemGroup from '../LeftMenu/LeftMenuItemGroup/LeftMenuItemGroup';
import HorizontalContainer from '../HorizontalContainer';
import { defaultIconProps } from '../../consts/defaultIconProps';
import { IconLogoShaker } from '../../assets/icon/iconLogo';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconClose } from '@consta/uikit/IconClose';
import styles from './MobileLeftMenu.module.scss';
import VerticalContainer from '../VerticalContainer';

/**
 * Левое меню в мобильной версии
 */
const MobileLeftMenu: FC<MobileLeftMenuProps> = ({ isOpen, onClose }) => {
  const organizationId = useAppSelector(selectOrganizationId());
  const role = useAppSelector(selectRole());

  const menuItemsList = useMemo(() => {
    return leftMenuGroupList.map((group) => ({
      ...group,
      listItems: group.listItems.filter((item) => {
        return (
          item.role.some((roleItem) => roleItem === role || role === Roles.ROOT) &&
          (!item.requiredOrganization || organizationId)
        );
      }),
    }));
  }, [role, organizationId]);

  return (
    <Sidebar
      isOpen={isOpen}
      className={styles.MobileLeftMenu}
      position="left"
      size="m"
      onClose={onClose}
      onClickOutside={onClose}
    >
      <HorizontalContainer space={0} align="center" className={styles.menu}>
        <Button size="m" view="clear" iconLeft={IconClose as any} onClick={onClose} />
        <IconLogoShaker size="s" className={styles.shakerLogo} {...defaultIconProps} />
      </HorizontalContainer>
      <VerticalContainer space="2xs">
        {menuItemsList.map((group) => (
          <LeftMenuItemGroup group={group} key={group.label} onClose={onClose} />
        ))}
      </VerticalContainer>
    </Sidebar>
  );
};

export default MobileLeftMenu;
