import React, { FC, useEffect, useState } from 'react';
import styles from '../../pages/App.module.scss';
import { useAppDispatch } from '../../app/hooks/store';
import UserSetting from './UserSetting';
import { getCabinetAction } from '../../state/user/actions';
import PageNavigate from './PageNavigate';
import { getOrganizationByIdAction } from '../../state/organization/actions';
import { getOrganizationId } from '../../helpers/getOrganizationId';
import useOrganizationRoleCheck from '../../hoooks/useOrganizationRoleCheck';
import classNames from 'classnames';
import { isMobile } from '../../pages/App';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconHamburger } from '../../assets/icon/iconHamburger';
import HorizontalContainer from '../HorizontalContainer';
import MobileLeftMenu from '../MobileLeftMenu';

/**
 * Возможные темы
 */
export enum ThemeName {
  gpnDefault = 'gpnDefault',
  gpnDark = 'gpnDark',
}

/**
 * Возможные языки
 */
export enum LanguageName {
  ru = 'ru',
  en = 'en',
}

/**
 * Свойства компонента TopMenu
 */
type TopMenuProps = {
  /**
   * Выбранная тема
   */
  theme: ThemeName;
  /**
   * Выбранный язык
   */
  language: LanguageName;
  /**
   * Обработчик изменения темы
   *
   * @param value новое значение темы
   */
  onThemeChange: ({ value }: { value: ThemeName }) => void;
  /**
   * Обработчик изменения языка
   *
   * @param value новое значение языка
   */
  onLanguageChange: ({ value }: { value: LanguageName }) => void;
};

/**
 * Верхнее меню
 */
const TopMenu: FC<TopMenuProps> = ({ theme, language, onThemeChange, onLanguageChange }) => {
  const dispatch = useAppDispatch();

  const organizationId = getOrganizationId();

  const [isMobileLeftMenuOpen, setIsMobileLeftMenuOpen] = useState(false);

  useOrganizationRoleCheck(organizationId);

  useEffect(() => {
    dispatch(getCabinetAction());
  }, [dispatch]);

  useEffect(() => {
    if (organizationId) {
      dispatch(getOrganizationByIdAction(organizationId));
      // dispatch(checkVendistaIntegrationAction(organizationId));
    }
  }, [dispatch, organizationId]);

  // Обработчики
  const handleMobileLeftMenuOpen = () => {
    setIsMobileLeftMenuOpen(true);
  };

  const handleMobileLeftMenuClose = () => {
    setIsMobileLeftMenuOpen(false);
  };

  return (
    <div className={classNames(styles.contextMenuAction, isMobile && styles.isMobile)}>
      <HorizontalContainer space="xs">
        {isMobile && (
          <Button
            size="m"
            view="clear"
            iconLeft={IconHamburger as any}
            onClick={handleMobileLeftMenuOpen}
          />
        )}
        <PageNavigate />
      </HorizontalContainer>
      <UserSetting
        theme={theme}
        language={language}
        onThemeChange={onThemeChange}
        onLanguageChange={onLanguageChange}
      />
      <MobileLeftMenu isOpen={isMobileLeftMenuOpen} onClose={handleMobileLeftMenuClose} />
    </div>
  );
};

export default TopMenu;
