import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import {
  selectIsLoadingPromoCodeList,
  selectPromoCodeList,
} from '../../../state/promoCode/selectors';
import {
  ActivePromoCodeFilterEnum,
  DiscountTypePromoCodeFilterEnum,
  SelectedPromoCodeFilterEnum,
} from './types';
import {
  PromoCodeListFiltersDTO,
  PromoCodeListFiltersReq,
  TypeDiscountEnum,
} from '../../../types/serverInterface/promoCodeDTO';
import { useEffect, useState } from 'react';
import { getPromoCodeListAction } from '../../../state/promoCode/actions';
import useDebounce from '../../../hoooks/useDebounce';

/**
 * Форматирование даты в строку
 *
 * @param date дата
 */
export function formatDateToString(date: Date | null): string | null {
  if (date === null) {
    return null;
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

/**
 * Трансформация типа скидки для отправки на сервер
 *
 * @param data данные формы типа скидки
 */
// TODO: исправить эту функцию
export const transformDiscountTypeDtoToReq = (
  data: DiscountTypePromoCodeFilterEnum,
): TypeDiscountEnum | null => {
  switch (data) {
    case DiscountTypePromoCodeFilterEnum.FREE:
      return TypeDiscountEnum.FREE;
    case DiscountTypePromoCodeFilterEnum.FIXED:
      return TypeDiscountEnum.FIXED;
    case DiscountTypePromoCodeFilterEnum.PERCENT:
      return TypeDiscountEnum.PERCENT;
    default:
      return null;
  }
};

/**
 * Трансформация формы фильтров для отправки на сервер
 *
 * @param filters значение формы настройки фильтров
 */
export const transformFiltersDtoToReq = (
  filters: PromoCodeListFiltersDTO,
): PromoCodeListFiltersReq => {
  const {
    periodTo,
    periodFrom,
    active,
    selected,
    discountType,
    discountAmountMin,
    discountAmountMax,
    qtyMin,
    qtyMax,
    createdSort,
    code,
  } = filters;

  // Нет размера скидки промокода
  const isDiscountNone =
    discountType === DiscountTypePromoCodeFilterEnum.NULL ||
    discountType === DiscountTypePromoCodeFilterEnum.FREE;

  return {
    discountAmountMin: isDiscountNone ? null : discountAmountMin,
    discountAmountMax: isDiscountNone ? null : discountAmountMax,
    qtyMin,
    qtyMax,
    periodTo: formatDateToString(periodTo),
    periodFrom: formatDateToString(periodFrom),
    isActive:
      active === ActivePromoCodeFilterEnum.NULL
        ? null
        : active === ActivePromoCodeFilterEnum.ACTIVE,
    isSelected:
      selected === SelectedPromoCodeFilterEnum.NULL
        ? null
        : selected === SelectedPromoCodeFilterEnum.SELECTED,
    discountType: transformDiscountTypeDtoToReq(discountType),
    createdSort,
    code,
  };
};

/**
 * Дефолтное значение фильтра списка промокодов
 */
export const promoCodeListInitialFilters: PromoCodeListFiltersDTO = {
  periodTo: null,
  periodFrom: null,
  qtyMin: 0,
  qtyMax: 100,
  discountType: DiscountTypePromoCodeFilterEnum.NULL,
  active: ActivePromoCodeFilterEnum.NULL,
  selected: SelectedPromoCodeFilterEnum.NULL,
  discountAmountMin: 0,
  discountAmountMax: 0,
  code: null,
  createdSort: null,
};

/**
 * hoc получения всех необходимых данных для страницы списка промокодов
 */
export const usePromoCodeList = (organizationId: number | null) => {
  const dispatch = useAppDispatch();

  const { state } = useAppSelector(selectPromoCodeList());
  const {
    page = 0,
    limit = 0,
    qty: fullLength = 100,
  } = state?.pagination || { page: 1, limit: 10, qty: 10 };
  const isLoading = useAppSelector(selectIsLoadingPromoCodeList());

  const promoCodeList = state?.data || [];

  const [filters, setFilters] = useState<PromoCodeListFiltersDTO>({
    ...promoCodeListInitialFilters,
  });
  const [isNoDebounceChange, setIsNoDebounceChange] = useState(false);

  const debouncedFilters = useDebounce(filters, 1000, isNoDebounceChange);

  useEffect(() => {
    organizationId &&
      dispatch(
        getPromoCodeListAction(organizationId, transformFiltersDtoToReq(debouncedFilters), {
          limit,
          page,
          qty: fullLength,
        }),
      );
  }, [dispatch, organizationId, debouncedFilters, limit, page, fullLength]);

  return {
    isLoading,
    promoCodeList,
    filters,
    fullLength,
    limit,
    page,
    debouncedFilters,
    setFilters,
    setIsNoDebounceChange,
  };
};
