import {
  SaleAndTransactionFilterInfo,
  SalesAndTransactionsFilter,
} from '../../../../types/serverInterface/OrganizationSalesDTO';
import { useExportFromExcel } from './useExportFromExcel';

/**
 * Хук экспорта сводного отчёта продаж организации
 *
 * @param filters фильтры списка продаж организации
 */
export const useExportSalesSummary = (
  filters: SalesAndTransactionsFilter,
  filterInfo: SaleAndTransactionFilterInfo,
) => {
  const { handleExcelExportClick } = useExportFromExcel(filters, filterInfo);

  return { handleExcelExportClick };
};
