import {
  DiscountTypePromoCodeFilterEnum,
  UsagePromoCodeEnum,
} from '../../../PromoCodePage/PromoCodeList/types';
import { SalesAndTransactionsFilter } from '../../../../types/serverInterface/OrganizationSalesDTO';

/**
 * Начальные значения для списка фильтров продаж
 */
export const initialValuesOrganizationSaleFilters: SalesAndTransactionsFilter = {
  machineModelIds: null,
  outletIds: null,
  machineIds: null,
  brandIds: null,
  cellCategoryIds: null,
  tasteIds: null,
  viewIds: null,
  cellPurposeIds: null,
  productLineIds: null,
  productIds: null,
  usageType: UsagePromoCodeEnum.NULL,
  discountType: DiscountTypePromoCodeFilterEnum.NULL,
  discountAmountMin: null,
  discountAmountMax: null,
  saleMethod: null,
  priceAmountMin: null,
  priceAmountMax: null,
  transactionStatus: null,
  dateStartSale: null,
  dateEndSale: null,
  organizationId: null,
  withPromoCode: null,
  limit: 10,
  offset: 0,
  page: 0,
};
