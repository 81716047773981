import { AppDispatch } from '../../app/store';
import {
  cancelTransactionThunk,
  createPromoCodeGroupThunk,
  createPromoCodeThunk,
  editPromoCodeThunk,
  generatePromoCodeThunk,
  getFilterPromoCodeThunk,
  getOrganizationSalesThunk,
  getPromoCodeGroupListThunk,
  getPromoCodeListByExportThunk,
  getPromoCodeListThunk,
  getPromoCodeThunk,
  getSalesSummaryThunk,
  switchSelectPromoCodeThunk,
} from './thunk';
import {
  CreatePromoCodeGroupReq,
  CreatePromoCodeReq,
  EditPromoCodeReq,
  PromoCodeListFiltersReq,
} from '../../types/serverInterface/promoCodeDTO';
import { Pagination } from '../../types/types';
import { SalesAndTransactionsFilter } from '../../types/serverInterface/OrganizationSalesDTO';
import { CancelTransactionDTO } from '../../types/serverInterface/TransactionDTO';

/**
 * Создание промокода
 *
 * @param data данные формы создания промокода
 */
export const createPromoCodeGroupAction =
  (data: CreatePromoCodeGroupReq) => (dispatch: AppDispatch) =>
    dispatch(createPromoCodeGroupThunk(data)).unwrap();

/**
 * Получение списка групп промокодов организации
 *
 * @param organizationId id организации
 */
export const getPromoCodeGroupListAction = (organizationId: number) => (dispatch: AppDispatch) =>
  dispatch(getPromoCodeGroupListThunk(organizationId));

/**
 * Генерация промокода
 *
 * @param organizationId id организации
 */
export const generatePromoCodeAction = (organizationId: number) => (dispatch: AppDispatch) =>
  dispatch(generatePromoCodeThunk(organizationId)).unwrap();

/**
 * Создания промокода
 *
 * @param data данные формы создания промокода
 */
export const createPromoCodeAction = (data: CreatePromoCodeReq) => (dispatch: AppDispatch) =>
  dispatch(createPromoCodeThunk(data)).unwrap();

/**
 * Изменение промокода
 *
 * @param data данные формы изменения промокода
 * @param promoCodeId  id промокода
 */
export const editPromoCodeAction =
  (data: EditPromoCodeReq, promoCodeId: number) => (dispatch: AppDispatch) =>
    dispatch(editPromoCodeThunk({ data, promoCodeId }));

/**
 * Получение информации о промокоде
 *
 * @param promoCodeId id промокода
 */
export const getPromoCodeAction = (promoCodeId: number) => (dispatch: AppDispatch) =>
  dispatch(getPromoCodeThunk(promoCodeId));

/**
 * Получение списка промокодов
 *
 * @param organizationId id организации
 * @param filters фильтры
 * @param pagination страницы
 */
export const getPromoCodeListAction =
  (organizationId: number, filters: PromoCodeListFiltersReq, pagination: Pagination) =>
  (dispatch: AppDispatch) =>
    dispatch(getPromoCodeListThunk({ filters, pagination, organizationId }));

/**
 * Получение списка промокодов для экспорта
 *
 * @param organizationId id организации
 * @param filters фильтры
 * @param pagination страницы
 */
export const getPromoCodeListByExportAction =
  (organizationId: number, filters: PromoCodeListFiltersReq, pagination: Pagination) =>
  (dispatch: AppDispatch) =>
    dispatch(getPromoCodeListByExportThunk({ filters, pagination, organizationId })).unwrap();

/**
 * Переключение состояние избранности промокода
 *
 * @param promoCodeId id промокода
 * @param isSelected флаг выбранного  промокода
 */
export const switchSelectPromoCodeAction =
  (promoCodeId: number, isSelected: boolean) => (dispatch: AppDispatch) =>
    dispatch(switchSelectPromoCodeThunk({ promoCodeId, isSelected })).unwrap();

/**
 * Получение пограничных значений для фильтров списка промокодов
 *
 * @param organizationId id организации
 * @param filters Текущие фильтры
 */
export const getFilterPromoCodeAction =
  (organizationId: number, filters: PromoCodeListFiltersReq) => (dispatch: AppDispatch) =>
    dispatch(getFilterPromoCodeThunk({ organizationId, filters }));

/**
 * Получение продаж организации
 *
 * @param filters фильтры списка продаж организации
 */
export const getOrganizationSalesAction =
  (filters: SalesAndTransactionsFilter) => (dispatch: AppDispatch) =>
    dispatch(getOrganizationSalesThunk(filters));

/**
 * Получение количества продаж по продуктам
 *
 * @param filters фильтры списка продаж организации
 */
export const getSalesSummaryAction =
  (filters: SalesAndTransactionsFilter) => (dispatch: AppDispatch) =>
    dispatch(getSalesSummaryThunk(filters)).unwrap();

/**
 * Отмена транзакции
 *
 * @param data данные для отмены транзакции
 * @param filters текущие фильтры
 */
export const cancelTransactionAction =
  (data: CancelTransactionDTO, filters: SalesAndTransactionsFilter) => (dispatch: AppDispatch) =>
    dispatch(cancelTransactionThunk(data)).then(() => {
      dispatch(getOrganizationSalesAction(filters));
    });
