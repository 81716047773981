import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import {
  cancelTransactionAction,
  getOrganizationSalesAction,
} from '../../../state/promoCode/actions';
import {
  selectOrganizationSales,
  selectOrganizationSalesFilters,
} from '../../../state/promoCode/selectors';
import VerticalContainer from '../../../components/VerticalContainer';
import {
  OrganizationSale,
  SaleAndTransactionFilterInfo,
  SalesAndTransactionsFilter,
} from '../../../types/serverInterface/OrganizationSalesDTO';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { getOrganizationId } from '../../../helpers/getOrganizationId';
import {
  getMachineModelListAction,
  getMachineSerialNumberListByOrganizationIdAction,
  getOutletListAction,
} from '../../../state/machineControl/actions';
import {
  selectMachineModelList,
  selectMachineSerialNumberList,
  selectOutletList,
} from '../../../state/machineControl/selectors';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconClose } from '../../../assets/icon/iconClose';
import HorizontalContainer from '../../../components/HorizontalContainer';
import { IconInfo } from '../../../assets/icon/iconInfo';
import Table from '../../../components/Table';
import { transformDate } from '../../../helpers/transformDate';
import styles from './OrganizationSaleList.module.scss';
import classNames from 'classnames';
import { TypeDiscountEnum } from '../../../types/serverInterface/promoCodeDTO';
import { useTranslation } from 'react-i18next';
import DefaultModal from '../../../components/DefaultModal';
import ContentCard from '../../../components/ContentCard';
import AlertButton from '../../../components/AlertButton';
import { IconWarning } from '../../../assets/icon/iconWarning';
import ButtonWithTooltip from '../../../components/withTooltip/Button';
import { VendistaTokenStatus } from '../../../types/serverInterface/vendistaDTO';
import { OutletListItem } from '../../../types/serverInterface/outletDTO';
import TablePageSettings from '../../../components/TablePageSettings';
import { checkVendistaTokenAction } from '../../../state/vendista/action';
import { selectCheckedVendistaToken } from '../../../state/vendista/selectors';
import { getMapById } from '../../../helpers/getMap';
import { initialValuesOrganizationSaleFilters } from './OrganizationSaleFilters/consts';
import OrganizationSaleFilters from './OrganizationSaleFilters';
import { Route, Routes } from 'react-router-dom';
import { UsagePromoCodeEnum } from '../../PromoCodePage/PromoCodeList/types';
import TableHeaderButton from '../../../components/ClassicTable/ClassicTableHeader/TableHeaderButton';
import { IconDocFilled } from '../../../assets/icon/iconDocFilled';
import { useExportSalesSummary } from './hooks';

const rowHeight = 64;

export const filterInfoInitialValue: SaleAndTransactionFilterInfo = {
  brandIds: null,
  tasteIds: null,
  cellCategoryIds: null,
  cellPurposeIds: null,
  dateEndSale: null,
  dateStartSale: null,
  machineModelIds: null,
  discountAmountMax: null,
  outletIds: null,
  machineIds: null,
  priceAmountMax: null,
  discountAmountMin: null,
  priceAmountMin: null,
  usageType: null,
  productLineIds: null,
  productIds: null,
  viewIds: null,
  transactionStatus: null,
  discountType: null,
};

/**
 * Продажи организации
 */
const OrganizationSaleList = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const organizationId = getOrganizationId();

  const { state: modelList } = useAppSelector(selectMachineModelList());
  const { state: machineSerialNumberList } = useAppSelector(selectMachineSerialNumberList());
  const { state: outletList } = useAppSelector(selectOutletList());

  const { state: salesListRes, isLoading: saleListIsLoading } =
    useAppSelector(selectOrganizationSales());
  const lastFilters = useAppSelector(selectOrganizationSalesFilters());

  const { state: checkedVendistaToken } = useAppSelector(selectCheckedVendistaToken());

  const salesList = salesListRes?.result || [];
  const pagination = salesListRes?.pagination;

  const filters: SalesAndTransactionsFilter = lastFilters || {
    ...initialValuesOrganizationSaleFilters,
    organizationId: organizationId,
  };

  const isVendistaTokenWorking =
    checkedVendistaToken?.statusToken !== VendistaTokenStatus.NOT_PROVIDED;

  const [isCancelTransactionModalOpen, setIsCancelTransactionModalOpen] = useState(false);
  const [selectedSale, setSelectedSale] = useState<OrganizationSale | null>(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [filterInfo, setFilterInfo] =
    useState<SaleAndTransactionFilterInfo>(filterInfoInitialValue);

  const machineModelMap = useMemo(() => getMapById(modelList || []), [modelList]);
  const outletMap = useMemo(() => getMapById(outletList?.outlets || []), [outletList]);
  const machineMap = useMemo(
    () => getMapById(machineSerialNumberList || []),
    [machineSerialNumberList],
  );

  const { handleExcelExportClick } = useExportSalesSummary(filters, filterInfo);

  useEffect(() => {
    filters.organizationId && dispatch(getOrganizationSalesAction(filters));
  }, [dispatch, JSON.stringify(filters)]);

  useEffect(() => {
    dispatch(getMachineModelListAction());
  }, [dispatch]);

  useEffect(() => {
    if (organizationId) {
      dispatch(getMachineSerialNumberListByOrganizationIdAction(organizationId));
      dispatch(checkVendistaTokenAction(organizationId));
      dispatch(getOutletListAction(organizationId, {}));
    }
  }, [dispatch, organizationId]);

  // Вспомогательные методы
  const getSalesLabel = (discountType: TypeDiscountEnum, discountAmount: number): string => {
    return `${discountAmount ? discountAmount : ''} ${t(`promoCode.list.column.discount.label.${discountType}`)}`;
  };

  // Обработчики
  const handleCancelTransactionModalOpen = () => {
    setIsCancelTransactionModalOpen(true);
  };

  const handleCancelTransactionModalClose = () => {
    setIsCancelTransactionModalOpen(false);
    setSelectedSale(null);
  };

  const handleCancelTransactionModalSubmit = () => {
    organizationId &&
      selectedSale &&
      selectedSale.integration &&
      selectedSale.terminalTransactionId &&
      dispatch(
        cancelTransactionAction(
          {
            organizationId: organizationId,
            integration: selectedSale.integration,
            terminalTransactionId: selectedSale.terminalTransactionId,
          },
          filters,
        ),
      )
        .then(() => {
          if (filters.organizationId) {
            return dispatch(getOrganizationSalesAction(filters));
          }
        })
        .then(() => setIsCancelTransactionModalOpen(false));
  };

  const handleInfoButtonClick = () => {
    setTooltipVisible((prevState) => !prevState);
  };

  const handleLimitChange = (newLimit: number) => {
    dispatch(getOrganizationSalesAction({ ...filters, limit: newLimit, page: 0, offset: 0 }));
  };

  const handleNextPage = () => {
    if ((filters.page + 1) * filters.limit < (pagination?.qty || 0)) {
      dispatch(
        getOrganizationSalesAction({
          ...filters,
          page: filters.page + 1,
          offset: filters.limit * (filters.page + 1),
        }),
      );
    }
  };

  const handlePreviousPage = () => {
    if (filters.page > 0) {
      dispatch(
        getOrganizationSalesAction({
          ...filters,
          page: filters.page - 1,
          offset: filters.limit * (filters.page - 1),
        }),
      );
    }
  };

  const handleRowClick = (row: OrganizationSale) => {
    setSelectedSale(row);
  };

  const handleFiltersSubmit = (
    filters: SalesAndTransactionsFilter,
    filterInfo: SaleAndTransactionFilterInfo,
  ) => {
    dispatch(
      getOrganizationSalesAction({
        ...filters,
        usageType: null,
        withPromoCode:
          filters.usageType === UsagePromoCodeEnum.NULL
            ? null
            : filters.usageType === UsagePromoCodeEnum.YES
              ? true
              : false,
        organizationId: organizationId,
      }),
    );

    setFilterInfo(filterInfo);
  };

  // render методы
  const renderMachineCell = (sale: OrganizationSale) => {
    const machine = sale?.machineId ? machineMap[sale.machineId] : null;

    const machineName = machine?.name || '';
    const modelId = machine?.serialNumber || '';
    const newModelId = machine?.modelId;
    const modelName =
      newModelId && machineModelMap[newModelId] ? machineModelMap[newModelId].name : '';

    return (
      <VerticalContainer space={0}>
        <Text size="m" className={styles.text}>
          {machineName}
        </Text>
        <HorizontalContainer space="2xs">
          <Text size="s" view="ghost" className={classNames(styles.text, styles.modelNameText)}>
            {modelName}
          </Text>
          {modelName !== '' && (
            <Text size="s" view="ghost" className={styles.text}>
              ({modelId})
            </Text>
          )}
        </HorizontalContainer>
      </VerticalContainer>
    );
  };

  const renderProductNameCell = (sale: OrganizationSale) => {
    return (
      <VerticalContainer space={0}>
        {sale.volume ? (
          <>
            <Text size="m">{sale.productName}</Text>
            <Text size="s" view="ghost">
              {sale.volume} {t(`productBase.units.${sale.unit}`)}
            </Text>
          </>
        ) : (
          <Text>-</Text>
        )}
      </VerticalContainer>
    );
  };

  const renderPriceCell = (sale: OrganizationSale) => {
    const isSaleCanceled = sale.isCanceled;

    return (
      <VerticalContainer>
        {isSaleCanceled ? (
          <Text>-</Text>
        ) : (
          <VerticalContainer space={0}>
            <Text size="m">
              {sale.transactionSum || sale.price} {t('organization.sales.ruble.text')}
            </Text>
            {sale.saleId && sale.transactionSum && sale.transactionSum !== sale.price && (
              <Text size="s" className={styles.lineThrough} view="ghost">
                {sale.price} {t('organization.sales.ruble.text')}
              </Text>
            )}
          </VerticalContainer>
        )}
      </VerticalContainer>
    );
  };

  const renderPromoCodeCell = (sale: OrganizationSale) => (
    <VerticalContainer space={0}>
      {sale.promoCode && sale.discountType && sale.discountAmount ? (
        <>
          <Text size="m">{sale.promoCode}</Text>
          <Text size="s" view="ghost">
            {getSalesLabel(sale.discountType, sale.discountAmount)}
          </Text>
        </>
      ) : (
        <Text>-</Text>
      )}
    </VerticalContainer>
  );

  const renderDateSaleCell = (sale: OrganizationSale) => {
    const dateSale = transformDate(
      sale.dateSale ? String(sale.dateSale) : String(sale.transactionTime),
    );

    const saleDay = dateSale.split(' ')[0];
    const saleTime = dateSale.split(' ')[1];

    return (
      <VerticalContainer space={0} align="end">
        <Text size="m">{saleDay}</Text>
        <Text size="s" view="ghost">
          {saleTime}
        </Text>
      </VerticalContainer>
    );
  };

  const renderTransactionInfoTooltip = () => {
    if (!selectedSale) {
      return;
    }

    const outletId = selectedSale?.outletId ?? null;
    const outlet = outletId ? outletMap[outletId] : null;

    const transactionDateSale = transformDate(String(selectedSale.transactionTime));

    const saleDay = transformDate(String(selectedSale.dateSale)).split(' ')[0];
    const saleTime = transformDate(String(selectedSale.dateSale)).split(' ')[1];

    const transactionDay = transactionDateSale.split(' ')[0];
    const transactionTime = transactionDateSale.split(' ')[1];

    return (
      <VerticalContainer space={0}>
        {selectedSale?.cardNumber && (
          <>
            <Text>{t('organization.sales.transactionInfo.cardNumber.tooltip.text')}</Text>
            <Text view="secondary">{selectedSale?.cardNumber}</Text>
          </>
        )}
        {outlet && (
          <>
            <Text>{t('organization.sales.transactionInfo.outlet.tooltip.text')}</Text>
            <Text view="secondary">{outlet?.name}</Text>
            <Text>{t('organization.sales.transactionInfo.outletAddress.tooltip.text')}</Text>
            <Text view="secondary">
              {t('organization.sales.transactionInfo.street.tooltip.text')} {outlet?.street},{' '}
              {outlet?.houseNumber}, {outlet?.city}, {outlet?.country}
            </Text>
          </>
        )}
        {selectedSale.dateSale && (
          <>
            <Text>{t('organization.sales.transactionInfo.timeMachine.tooltip.text')}</Text>
            <Text view="secondary">
              {saleDay} {saleTime}
            </Text>
          </>
        )}
        <Text>{t('organization.sales.transactionInfo.timeVendista.tooltip.text')}</Text>
        <Text view="secondary">
          {transactionDay} {transactionTime}
        </Text>
      </VerticalContainer>
    );
  };

  const renderTerminalTransactionIdCell = (sale: OrganizationSale) => {
    return (
      <HorizontalContainer space="xs" align="center">
        {sale.isCanceled ? (
          <ButtonWithTooltip
            view="clear"
            size="s"
            className={styles.iconWarning}
            iconLeft={IconWarning as any}
            disabled
            tooltipProps={{
              content: t('organization.sales.canceledTransaction.info.tooltip.text'),
            }}
          />
        ) : (
          <Button
            view="clear"
            size="s"
            iconLeft={IconClose as any}
            disabled={!sale.transactionId}
            onClick={handleCancelTransactionModalOpen}
          />
        )}
        <ButtonWithTooltip
          onlyIcon
          iconLeft={IconInfo as any}
          view="clear"
          size="s"
          disabled={!sale.transactionId}
          onClick={handleInfoButtonClick}
          tooltipProps={{
            content: renderTransactionInfoTooltip() as any,
            mode: 'click',
            closeOnClickOutside: true,
            direction: 'downCenter',
          }}
        />
      </HorizontalContainer>
    );
  };

  const renderCardInfo = () => (
    <HorizontalContainer space="s">
      {selectedSale?.cardNumber && (
        <ContentCard className={styles.contentCard}>
          <VerticalContainer space={0}>
            <Text view="secondary">
              {t('organization.sales.cancelTransaction.cardNumber.label')}
            </Text>
            <Text>{selectedSale?.cardNumber}</Text>
          </VerticalContainer>
        </ContentCard>
      )}
      <ContentCard className={styles.contentCard}>
        <VerticalContainer space={0}>
          <Text view="secondary">{t('organization.sales.cancelTransaction.price.label')}</Text>
          <Text>
            {selectedSale?.transactionSum || selectedSale?.price}{' '}
            {t('organization.sales.ruble.text')}
          </Text>
        </VerticalContainer>
      </ContentCard>
    </HorizontalContainer>
  );

  const renderProductInfo = () => (
    <ContentCard className={styles.contentCard}>
      <VerticalContainer space={0}>
        <Text view="secondary">{t('organization.sales.cancelTransaction.product.label')}</Text>
        {selectedSale?.productName || selectedSale?.volume ? (
          <Text size="m">
            {selectedSale.productName}{' '}
            {selectedSale.volume + ' ' + t(`productBase.units.${selectedSale.unit}`)}
          </Text>
        ) : (
          <Text>-</Text>
        )}
      </VerticalContainer>
    </ContentCard>
  );

  const renderOutletInfo = (outlet: OutletListItem) => (
    <ContentCard className={styles.contentCard}>
      <VerticalContainer space={0}>
        <Text view="secondary">{t('organization.sales.cancelTransaction.outlet.label')}</Text>
        <Text>{outlet?.name}</Text>
      </VerticalContainer>
      <VerticalContainer space={0}>
        <Text view="secondary">
          {t('organization.sales.cancelTransaction.outletAddress.label')}
        </Text>
        <Text>
          {t('organization.sales.cancelTransaction.street.text')} {outlet?.street},{' '}
          {outlet?.houseNumber}, {outlet?.city}, {outlet?.country}
        </Text>
      </VerticalContainer>
    </ContentCard>
  );

  const renderMachineInfo = (modelName: string, machineName: string) => (
    <ContentCard className={styles.contentCard}>
      <VerticalContainer space={0}>
        <Text view="secondary">{t('organization.sales.cancelTransaction.modelName.label')}</Text>
        <Text>{machineName} </Text>
      </VerticalContainer>
      <VerticalContainer space={0}>
        <Text view="secondary">{t('organization.sales.cancelTransaction.machineName.label')}</Text>
        <Text>{modelName}</Text>
      </VerticalContainer>
      <VerticalContainer space={0}>
        <Text view="secondary">{t('organization.sales.cancelTransaction.serialNumber.label')}</Text>
        <Text>{selectedSale?.machineSerialNumber}</Text>
      </VerticalContainer>
    </ContentCard>
  );

  const renderDateTimeInfo = (saleDay: string, saleTime: string) => (
    <HorizontalContainer space="s">
      <ContentCard className={styles.contentCard}>
        <VerticalContainer space={0}>
          <Text view="secondary">{t('organization.sales.cancelTransaction.date.label')}</Text>
          <Text>{saleDay}</Text>
        </VerticalContainer>
      </ContentCard>
      <ContentCard className={styles.contentCard}>
        <VerticalContainer space={0}>
          <Text view="secondary">{t('organization.sales.cancelTransaction.time.label')}</Text>
          <Text>{saleTime}</Text>
        </VerticalContainer>
      </ContentCard>
    </HorizontalContainer>
  );

  const renderModalContent = () => {
    if (!selectedSale) {
      return;
    }

    const machine = !!selectedSale.machineSerialNumber
      ? machineSerialNumberList?.find(
          (machine) => machine.serialNumber === selectedSale.machineSerialNumber,
        )
      : null;
    const machineName = machine?.name || '';
    const modelId = machine?.modelId;
    const modelName = modelId && machineModelMap[modelId] ? machineModelMap[modelId].name : '';
    const outletId = selectedSale?.outletId ?? null;
    const outlet = outletId ? outletMap[outletId] : null;

    const dateSale = transformDate(
      selectedSale.dateSale ? String(selectedSale.dateSale) : String(selectedSale.transactionTime),
    );

    const saleDay = dateSale.split(' ')[0];
    const saleTime = dateSale.split(' ')[1];

    return (
      <VerticalContainer space="xs" className={styles.verticalContainer}>
        {renderCardInfo()}
        {renderProductInfo()}
        {outlet && renderOutletInfo(outlet)}
        {machineName && modelName && renderMachineInfo(machineName, modelName)}
        {renderDateTimeInfo(saleDay, saleTime)}
      </VerticalContainer>
    );
  };

  const renderActionsModal = () => (
    <HorizontalContainer space="s">
      <Button
        label={t('organization.sales.cancelTransaction.back.button.label')}
        size="m"
        view="clear"
        onClick={handleCancelTransactionModalClose}
      />
      <AlertButton
        label={t('organization.sales.cancelTransaction.cancel.button.label')}
        size="m"
        onClick={handleCancelTransactionModalSubmit}
      />
    </HorizontalContainer>
  );

  const renderRightSide = () => (
    <TablePageSettings
      page={pagination?.page || 0}
      limit={pagination?.limit || 0}
      fullLength={pagination?.qty || 0}
      onLimitChange={handleLimitChange}
      onNextClick={handleNextPage}
      onBackClick={handlePreviousPage}
    />
  );

  const renderFilterModal = () => (
    <Routes>
      <Route path="/filters" element={<OrganizationSaleFilters onSubmit={handleFiltersSubmit} />} />
    </Routes>
  );

  const renderLeftSideHeader = () => (
    <HorizontalContainer space="xs">
      <TableHeaderButton
        label={t('promoCode.list.actions.importExcel.button.label')}
        // tooltipText="Подробное описание кнопки"
        disabled={false}
        iconLeft={IconDocFilled as any}
        onClick={handleExcelExportClick}
      />
    </HorizontalContainer>
  );

  if (!salesList) {
    return null;
  }

  return (
    <VerticalContainer className={styles.OrganizationSaleList}>
      {renderFilterModal()}
      <DefaultModal
        modalTitle={t('organization.sales.cancelTransaction.modal.title')}
        className={styles.CancelTransactionModal}
        isOpen={isCancelTransactionModalOpen}
        renderActions={renderActionsModal}
        onClose={handleCancelTransactionModalClose}
      >
        {renderModalContent()}
      </DefaultModal>
      <Table
        rowClassName={styles.rowClassName}
        isLoading={saleListIsLoading}
        renderLeftSide={renderLeftSideHeader}
        renderRightSide={renderRightSide}
        rowHeight={rowHeight}
        rows={salesList || []}
        columns={[
          {
            columnWidth: '23.9%',
            title: t('organization.sales.table.machine.column.title'),
            titleTextProp: { weight: 'semibold', size: 'm', view: 'secondary' },
            renderCell: renderMachineCell,
          },
          {
            columnWidth: '23.8%',
            title: t('organization.sales.table.product.column.title'),
            titleTextProp: { weight: 'semibold', size: 'm', view: 'secondary' },
            renderCell: renderProductNameCell,
          },
          {
            columnWidth: '1fr',
            title: t('organization.sales.table.price.column.title'),
            titleTextProp: { weight: 'semibold', size: 'm', view: 'secondary' },
            renderCell: renderPriceCell,
          },
          {
            columnWidth: !isVendistaTokenWorking ? '26.4%' : '21.8%',
            title: t('organization.sales.table.promoCode.column.title'),
            titleTextProp: { weight: 'semibold', size: 'm', view: 'secondary' },
            renderCell: renderPromoCodeCell,
          },
          {
            columnWidth: '4.9%',
            justify: 'right',
            title: t('organization.sales.table.date.column.title'),
            titleTextProp: { weight: 'semibold', size: 'm', view: 'secondary' },
            renderCell: renderDateSaleCell,
          },
          {
            isNoRender: !isVendistaTokenWorking,
            columnWidth: '8.4%',
            justify: 'right',
            title: '',
            renderCell: renderTerminalTransactionIdCell,
          },
        ]}
        onRowClick={handleRowClick}
      />
    </VerticalContainer>
  );
};

export default OrganizationSaleList;
