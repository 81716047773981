import React, { FC, useEffect, useState } from 'react';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import VendistaSignInForm from './VendistaSignInForm';
import { Text, TextPropView } from '@consta/uikit/__internal__/src/components/Text';
import { IconClose } from '@consta/uikit/IconClose';
import VerticalContainer from '../../../../components/VerticalContainer';
import ContentCard from '../../../../components/ContentCard';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import styles from './VendistaSettings.module.scss';
import { IconLogoVendista } from '../../../../assets/icon/iconLogoVendista';
import { defaultIconProps } from '../../../../consts/defaultIconProps';
import { VendistaSettingsProps } from './types';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import { selectCabinet } from '../../../../state/user/selectors';
import { getCabinetAction } from '../../../../state/user/actions';
import VendistaSignOutForm from './VendistaSignOutForm';
import { useTranslation } from 'react-i18next';
import { selectCheckedVendistaToken } from '../../../../state/vendista/selectors';
import { VendistaTokenStatus } from '../../../../types/serverInterface/vendistaDTO';
import { checkVendistaTokenAction } from '../../../../state/vendista/action';

/**
 * Страница настройки Vendista
 */
const VendistaSettings: FC<VendistaSettingsProps> = ({
  organizationId,
  isShowCard,
  isOpenSignInModal,
  handleSignInVendistaModalClose,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const user = useAppSelector(selectCabinet());
  const { state } = useAppSelector(selectCheckedVendistaToken());

  const [isOpenDeleteVendistaModal, setIsOpenDeleteVendistaModal] = useState(false);

  const userLabel = `${user?.name} ${user?.surname}`;

  useEffect(() => {
    dispatch(getCabinetAction());
    organizationId && dispatch(checkVendistaTokenAction(organizationId));
  }, [dispatch, organizationId]);

  // Вспомогательные методы
  const getVendistaInfoText = () => {
    switch (state?.statusToken) {
      case VendistaTokenStatus.NOT_WORKING:
        return 'integration.vendista.info.status.not_working.label';
      case VendistaTokenStatus.WORKING:
        return 'integration.vendista.info.status.working.label';
      case VendistaTokenStatus.UNVERIFIED:
        return 'integration.vendista.info.status.unverified.label';
      default:
        return '';
    }
  };

  const getVendistaInfoStatus = (): TextPropView => {
    switch (state?.statusToken) {
      case VendistaTokenStatus.NOT_WORKING:
        return 'alert';
      case VendistaTokenStatus.WORKING:
        return 'success';
      case VendistaTokenStatus.UNVERIFIED:
        return 'warning';
      default:
        return 'system';
    }
  };

  // Обработчики
  const handleCloseDeleteVendistaModal = () => {
    setIsOpenDeleteVendistaModal(false);
  };

  const handleOpenDeleteVendistaModal = () => {
    setIsOpenDeleteVendistaModal(true);
  };

  // render методы
  const renderVendistaInfo = () => (
    <HorizontalContainer space="m">
      <IconLogoVendista size="m" className={styles.VendistaLogo} {...defaultIconProps} />
      <VerticalContainer space={0}>
        <Text size="l" weight="medium">
          {userLabel}
        </Text>
        <Text size="m" view={getVendistaInfoStatus()}>
          {t(getVendistaInfoText())}
        </Text>
      </VerticalContainer>
    </HorizontalContainer>
  );

  const renderDeleteButton = () => (
    <HorizontalContainer space="s">
      <Button
        size="m"
        view="clear"
        onClick={handleOpenDeleteVendistaModal}
        iconLeft={IconClose as any}
        onlyIcon
      />
    </HorizontalContainer>
  );

  const renderVendistaSettings = () =>
    isShowCard && (
      <div>
        <ContentCard className={styles.VendistaNotification}>
          <HorizontalContainer isAutoSpace align="center">
            {renderVendistaInfo()}
            {renderDeleteButton()}
          </HorizontalContainer>
        </ContentCard>
      </div>
    );

  const renderSignInForm = () =>
    isOpenSignInModal &&
    organizationId && (
      <VendistaSignInForm
        organizationId={organizationId}
        onClose={handleSignInVendistaModalClose}
      />
    );

  const renderSignOutForm = (organizationId: number) => (
    <VendistaSignOutForm
      organizationId={organizationId}
      isOpen={isOpenDeleteVendistaModal}
      onClose={handleCloseDeleteVendistaModal}
    />
  );

  if (!organizationId) return null;

  return (
    <div className={styles.VendistaSettings}>
      {renderVendistaSettings()}
      {renderSignInForm()}
      {renderSignOutForm(organizationId)}
    </div>
  );
};

export default VendistaSettings;
