import { FC } from 'react';
import DefaultModal from '../../../../../../../components/DefaultModal';
import HorizontalContainer from '../../../../../../../components/HorizontalContainer';
import { TextField } from '@consta/uikit/TextField';
import { getInputNumberValue } from '../../../../../../../helpers/ inputHelpers';
import { Switch } from '@consta/uikit/Switch';
import { MachineStorageInfoProductCellFormatted } from '../../../../../../../types/serverInterface/machineDTO';
import { useFormik } from 'formik';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { editCellSettingValidationScheme } from './editCellSettingValidationScheme';
import { useTranslation } from 'react-i18next';
import styles from './MachineFillingEditListItemSettings.module.scss';
import classNames from 'classnames';
import { getViewStorageItemClassName } from '../../../../../../../components/Machine/MachineStorage/helpers';
import { ProductGroup, ProductView } from '../../../../../../../types/serverInterface/storageDTO';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { IconCopy } from '@consta/uikit/IconCopy';
import { addFrontNotification } from '../../../../../../../state/frontNotification/slice';
import { useAppDispatch } from '../../../../../../../app/hooks/store';

/**
 * Свойства компонента MachineFillingEditListItemSettings
 */
type MachineFillingEditListItemSettingsProps = {
  /**
   * Флаг открытия
   */
  isOpen: boolean;
  /**
   * Флаг активности ячейки
   */
  isActive: boolean;
  /**
   * Название ингредиента
   */
  fullIngredientName: string | null;
  /**
   * Внешний вид продукта
   */
  view: ProductView;
  /**
   * Номер ячейки
   */
  cellNumber: number;
  /**
   * Максимальный объём ячейки
   */
  maxVolume: number;
  /**
   * Минимальный объём ячейки(для высыпания ячейки)
   */
  minVolume: number;
  /**
   * Обработчик закрытия настроек
   */
  onCloseCellSettings: () => void;
  /**
   * Обработчик изменений текстового поля
   *
   * @param key ключ поля
   * @param value значение
   */
  onInputChange: (
    key: keyof MachineStorageInfoProductCellFormatted,
  ) => ({ value }: { value: string | null }) => void;
  /**
   * Обработчик изменений boolean поля
   *
   * @param key ключ поля
   * @param checked значение
   */
  onBooleanChange: (
    key: keyof MachineStorageInfoProductCellFormatted,
  ) => ({ checked }: { checked: boolean }) => void;
};

export type CellSettingForm = {
  isActive: boolean;
  minVolume: number;
  maxVolume: number;
};

/**
 * Настройка ячейки продукта автомата
 */
const MachineFillingEditListItemSettings: FC<MachineFillingEditListItemSettingsProps> = ({
  isOpen,
  isActive,
  fullIngredientName,
  view,
  cellNumber,
  minVolume,
  maxVolume,
  onCloseCellSettings,
  onInputChange,
  onBooleanChange,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const formik = useFormik({
    validationSchema: editCellSettingValidationScheme,
    initialValues: {
      isActive: isActive,
      minVolume,
      maxVolume,
    },
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  // Обработчики
  function handleSubmit(data: CellSettingForm) {
    onInputChange('maxVolume')({ value: String(data.maxVolume) });
    onInputChange('minVolume')({ value: String(data.minVolume) });
    onBooleanChange('isActive')({ checked: data.isActive });

    onCloseCellSettings();
  }

  const handleIngredientInfoCopy = () => {
    fullIngredientName &&
      navigator.clipboard.writeText(fullIngredientName).then(
        function () {
          dispatch(
            addFrontNotification({
              status: 'success',
              text: 'machineControl.machine.fillingEdit.notifications.textCopied.label',
            }),
          );
        },
        function () {
          dispatch(
            addFrontNotification({
              status: 'alert',
              text: 'machineControl.machine.fillingEdit.notifications.textCopiedError.label',
            }),
          );
        },
      );
  };

  // render методы
  const renderActions = () => (
    <>
      <Button
        label={t('machineControl.machine.fillingEdit.settings.back.button.label')}
        view="clear"
        onClick={onCloseCellSettings}
      />
      <Button
        label={t('machineControl.machine.fillingEdit.settings.edit.button.label')}
        type="submit"
        onClick={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      />
    </>
  );

  const renderCellNumber = () => (
    <div
      key={cellNumber}
      className={classNames(
        styles.cellNumberCard,
        getViewStorageItemClassName(ProductGroup.POWDER, view),
      )}
    >
      <Text weight="semibold" className={styles.text}>
        {cellNumber}
      </Text>
    </div>
  );

  const renderIngredientInfo = () => (
    <div className={styles.card}>
      <Text size="l" weight="semibold">
        {t('machineControl.machine.prices.calibration.product.label')}
      </Text>
      <HorizontalContainer className={styles.ingredientInfo} space="m">
        {renderCellNumber()}
        {fullIngredientName && (
          <HorizontalContainer space="m" isAutoSpace>
            <Text size="l">{fullIngredientName}</Text>
            <Button
              size="xs"
              onlyIcon
              view="clear"
              iconLeft={IconCopy as any}
              onClick={handleIngredientInfoCopy}
            />
          </HorizontalContainer>
        )}
      </HorizontalContainer>
    </div>
  );

  const renderContent = () => (
    <div className={styles.card}>
      <HorizontalContainer>
        <TextField
          label={t('machineControl.machine.fillingEdit.settings.maxVolume.input.label')}
          name="maxVolume"
          type="number"
          width="full"
          incrementButtons={false}
          value={getInputNumberValue(formik.values.maxVolume)}
          onChange={({ e }) => formik.handleChange(e)}
        />
        <TextField
          label={t('machineControl.machine.fillingEdit.settings.minVolume.input.label')}
          name="minVolume"
          type="number"
          width="full"
          incrementButtons={false}
          value={getInputNumberValue(formik.values.minVolume)}
          onChange={({ e }) => formik.handleChange(e)}
        />
      </HorizontalContainer>
      <Switch
        label={t('machineControl.machine.fillingEdit.settings.container.switch.label')}
        checked={!formik.values.isActive}
        onChange={({ checked }) =>
          formik.setValues((prevState) => ({ ...prevState, isActive: !checked }))
        }
      />
    </div>
  );

  return (
    <DefaultModal
      className={styles.MachineFillingEditListItemSettings}
      isOpen={isOpen}
      modalTitle={`${t('machineControl.machine.fillingEdit.settings.modal.title')}${cellNumber}`}
      onClose={onCloseCellSettings}
      renderActions={renderActions}
    >
      {renderIngredientInfo()}
      {renderContent()}
    </DefaultModal>
  );
};

export default MachineFillingEditListItemSettings;
