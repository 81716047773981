import { Route, Routes } from 'react-router-dom';
import OrganizationSaleList from './OrganizationSaleList';

/**
 * Роут продаж организации
 */
const SalesPage = () => {
  return (
    <Routes>
      <Route path="/organization/*" element={<OrganizationSaleList />} />
    </Routes>
  );
};

export default SalesPage;
