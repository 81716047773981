import { FC, useEffect, useState } from 'react';
import ContentCard from '../../../../../components/ContentCard';
import styles from './MachineVendistaSetting.module.scss';
import { defaultIconProps } from '../../../../../consts/defaultIconProps';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks/store';
import {
  checkVendistaTokenAction,
  editMachineVendistaSettingAction,
  getMachineVendistaSettingAction,
} from '../../../../../state/vendista/action';
import {
  selectCheckedVendistaToken,
  selectMachineVendistaSetting,
} from '../../../../../state/vendista/selectors';
import { IconArrowRight } from '@consta/uikit/IconArrowRight';
import DefaultModal from '../../../../../components/DefaultModal';
import { TextField } from '@consta/uikit/TextField';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { useFormik } from 'formik';
import { editMachineVendistaSettingValidationScheme } from './editMachineVendistaSettingValidationScheme';
import { IconCalculator } from '../../../../../assets/icon/iconCalculator';
import { useTranslation } from 'react-i18next';
import { MachineVendistaSettingProps } from './types';
import { VendistaTokenStatus } from '../../../../../types/serverInterface/vendistaDTO';

/**
 * Страница подключения Vendista к автомату
 */
const MachineVendistaSetting: FC<MachineVendistaSettingProps> = ({
  machineId,
  machineSerialNumber,
  organizationId,
  isSnackMachine,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { state: checkedVendistaToken } = useAppSelector(selectCheckedVendistaToken());
  const { state: machineVendistaSettingInfo } = useAppSelector(selectMachineVendistaSetting());

  const [isEditOpen, setIsEditOpen] = useState(false);

  useEffect(() => {
    dispatch(getMachineVendistaSettingAction(organizationId, machineId));
    dispatch(checkVendistaTokenAction(organizationId));
  }, [dispatch, machineId, organizationId]);

  const formik = useFormik({
    validationSchema: editMachineVendistaSettingValidationScheme,
    initialValues: {
      vendistaTerminalId: machineVendistaSettingInfo?.termId
        ? String(machineVendistaSettingInfo.termId)
        : '',
      machineSerialNumber: machineSerialNumber,
      isSnackMachine,
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (data) => {
      const termIdNumber = data.vendistaTerminalId ? Number(data.vendistaTerminalId) : null;

      dispatch(
        editMachineVendistaSettingAction(organizationId, machineId, {
          vendistaTerminalId: termIdNumber,
          machineSerialNumber: data.machineSerialNumber,
          isSnackMachine,
        }),
      ).then(() => handleEditClose());
    },
  });

  // Обработчики
  const handleEditOpenClick = () => {
    setIsEditOpen(true);
  };

  const handleEditClose = () => {
    setIsEditOpen(false);
  };

  // render методы
  const renderTermId = () =>
    machineVendistaSettingInfo?.termId ? (
      <Text size="m" weight="regular" view="secondary">
        {machineVendistaSettingInfo?.termId}
      </Text>
    ) : (
      <Text size="m" weight="regular" view="secondary">
        {t('machineControl.machine.settings.vendista.noIntegration.label')}
      </Text>
    );

  const renderModalAction = () => (
    <>
      <Button
        size="l"
        label={t('machineControl.machine.settings.vendista.back.button.label')}
        view="clear"
        onClick={handleEditClose}
      />
      <Button
        size="l"
        label={t('machineControl.machine.settings.vendista.save.button.label')}
        onClick={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      />
    </>
  );

  const renderEditModal = () => (
    <DefaultModal
      className={styles.editModal}
      isOpen={isEditOpen}
      modalTitle={t('machineControl.machine.settings.vendista.modal.title')}
      onClose={handleEditClose}
      renderActions={renderModalAction}
    >
      <TextField
        size="l"
        label={t('machineControl.machine.settings.vendista.vendistaTerminalId.input.label')}
        placeholder={t(
          'machineControl.machine.settings.vendista.vendistaTerminalId.input.placeholder',
        )}
        name="vendistaTerminalId"
        type="number"
        incrementButtons={false}
        width="full"
        value={formik.values.vendistaTerminalId}
        status={formik.errors.vendistaTerminalId ? 'alert' : undefined}
        caption={formik.errors.vendistaTerminalId && t(formik.errors.vendistaTerminalId)}
        onChange={({ e }) => formik.handleChange(e)}
      />
    </DefaultModal>
  );

  if (
    checkedVendistaToken?.statusToken &&
    checkedVendistaToken?.statusToken === VendistaTokenStatus.NOT_PROVIDED
  )
    return null;

  return (
    <ContentCard className={styles.MachineVendistaSetting}>
      {renderEditModal()}
      <HorizontalContainer space="l" align="center">
        <IconCalculator size="m" {...defaultIconProps} />
        <VerticalContainer space="2xs">
          <Text size="m" weight="medium">
            {t('machineControl.machine.settings.vendista.vendistaTerm')}
          </Text>
          {renderTermId()}
        </VerticalContainer>
      </HorizontalContainer>
      <HorizontalContainer space="l" align="center">
        {!machineVendistaSettingInfo?.termId && <div className={styles.errorStatus}></div>}
        <IconArrowRight {...defaultIconProps} size="m" onClick={handleEditOpenClick} />
      </HorizontalContainer>
    </ContentCard>
  );
};

export default MachineVendistaSetting;
