import { RootState } from '../../app/store';

/**
 * Селектор получения токена vendista
 */
export const selectCheckedVendistaToken = () => (state: RootState) => state.vendista.vendista;

/**
 * Селектор получения номера терминала vendista автомата
 */
export const selectMachineVendistaSetting = () => (state: RootState) =>
  state.vendista.vendistaMachineSetting;

/**
 * Селектор получения списка настроек интеграции vendista на автомате
 */
export const selectMachineVendistaSettingList = () => (state: RootState) =>
  state.vendista.machineVendistaSettingList;
