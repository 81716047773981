import { useEffect } from 'react';
import { Roles } from '../../types/serverInterface/cabinetDTO';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks/store';
import { selectCabinet } from '../../state/user/selectors';

/**
 * hook проверки доступа в организацию
 */
const useOrganizationRoleCheck = (organizationId: number | null | undefined) => {
  const navigate = useNavigate();
  const cabinet = useAppSelector(selectCabinet());

  useEffect(() => {
    if (
      cabinet &&
      organizationId &&
      cabinet.role !== Roles.MANUFACTURER &&
      cabinet.role !== Roles.ROOT &&
      cabinet.role !== Roles.DEVELOPER
    ) {
      !cabinet.organizationIds.find((id) => id === organizationId) && navigate('/errorPage');
    }
  }, [cabinet, organizationId, navigate]);
};

export default useOrganizationRoleCheck;
