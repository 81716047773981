import { FC, SyntheticEvent } from 'react';
import DefaultFullScreenModal from '../../../../components/DefaultFullScreenModal';
import VerticalContainer from '../../../../components/VerticalContainer';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import { TextField } from '@consta/uikit/TextField';
import styles from './CreateSnackProduct.module.scss';
import { useTranslation } from 'react-i18next';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import ContentCard from '../../../../components/ContentCard';
import RequiredText from '../../../../components/RequiredText';
import { useFormik } from 'formik';
import {
  CreateSnackBrandDTO,
  CreateSnackProductDTO,
} from '../../../../types/serverInterface/SnackProductBaseDTO';
import { useAppDispatch } from '../../../../app/hooks/store';
import {
  createSnackBrandAction,
  createSnackProductAction,
  getSnackBrandListAction,
} from '../../../../state/productBase/actions';
import { creationValidationScheme } from './creationValidationScheme';
import { CreateSnackProductProps } from './types';
import { Combobox } from '@consta/uikit/Combobox';
import { initialSnackProductValues } from './consts';

/**
 * Модальное окно для создания нового продукта
 */
const CreateSnackProduct: FC<CreateSnackProductProps> = ({
  brandList,
  organizationId,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const breadcrumbs = [
    {
      label: t('snackProductBase.title'),
      onClick: handleClose,
    },
    {
      label: t('snackProductBase.create.title'),
    },
  ];

  const formik = useFormik({
    validationSchema: creationValidationScheme,
    validateOnChange: false,
    initialValues: initialSnackProductValues,
    onSubmit: handleSubmit,
  });

  // Обработчики
  function handleClose() {
    onClose();
  }

  function handleSubmit(data: CreateSnackProductDTO) {
    const brandId = formik.values.brandId;

    const convertToNumberOrNull = (value: number | null) => (value !== null ? Number(value) : null);

    const requestData: CreateSnackProductDTO = {
      ...data,
      calories: convertToNumberOrNull(data.calories),
      proteins: convertToNumberOrNull(data.proteins),
      fats: convertToNumberOrNull(data.fats),
      carbohydrates: convertToNumberOrNull(data.carbohydrates),
      brandId: formik.values.brandId,
    };

    brandId &&
      organizationId &&
      dispatch(createSnackProductAction(requestData, brandId, organizationId)).then(onClose);
  }

  const handleCreateBrand = ({ label }: { e: SyntheticEvent; label: string }) => {
    if (!organizationId) return;

    const newBrandData: CreateSnackBrandDTO = { name: label };

    dispatch(createSnackBrandAction(newBrandData, organizationId))
      .unwrap()
      .then((newBrand) => {
        formik.setFieldValue('brandId', newBrand.id);
        dispatch(getSnackBrandListAction(organizationId));
      });
  };

  // render методы
  const renderMainInformation = () => {
    return (
      <ContentCard className={styles.MainInformation}>
        <Text size="xl" weight="semibold">
          {t('snackProductBase.create.mainInformation.title')}
        </Text>
        <VerticalContainer align="start">
          <HorizontalContainer space="m">
            <HorizontalContainer align="center" className={styles.textContainer}>
              <RequiredText size="m" view="primary">
                {t('snackProductBase.create.mainInformation.brand.item.label')}
              </RequiredText>
            </HorizontalContainer>
            <Combobox
              className={styles.inputContainer}
              placeholder={t('snackProductBase.create.mainInformation.brand.input.placeholder')}
              items={brandList}
              value={brandList.find(({ id }) => id === formik.values.brandId) || null}
              getItemLabel={(item) => item.name}
              labelForCreate={t('snackProductBase.create.mainInformation.brand.create.label')}
              caption={formik.errors.brandId || undefined}
              onChange={({ value }) => {
                formik.setFieldValue('brandId', value?.id || null);
              }}
              onCreate={handleCreateBrand}
            />
          </HorizontalContainer>
          <HorizontalContainer space="m">
            <HorizontalContainer align="center" className={styles.textContainer}>
              <RequiredText size="m" view="primary">
                {t('snackProductBase.create.mainInformation.name.item.label')}
              </RequiredText>
            </HorizontalContainer>
            <TextField
              className={styles.inputContainer}
              placeholder={t('snackProductBase.create.mainInformation.name.input.placeholder')}
              value={formik.values.name}
              status={formik.errors.name ? 'alert' : undefined}
              caption={formik.errors.name}
              onChange={({ value }) => formik.setFieldValue('name', value)}
            />
          </HorizontalContainer>
          <HorizontalContainer space="m">
            <HorizontalContainer align="center" className={styles.textContainer}>
              <RequiredText size="m" view="primary">
                {t('snackProductBase.create.mainInformation.barcode.item.label')}
              </RequiredText>
            </HorizontalContainer>
            <TextField
              className={styles.inputCodesContainer}
              placeholder={t('snackProductBase.create.mainInformation.barcode.input.placeholder')}
              value={formik.values.barcode}
              status={formik.errors.barcode ? 'alert' : undefined}
              caption={formik.errors.barcode}
              onChange={({ value }) => formik.setFieldValue('barcode', value)}
            />
          </HorizontalContainer>
          <HorizontalContainer space="m">
            <HorizontalContainer align="center" className={styles.textContainer}>
              <Text size="m" view="primary">
                {t('snackProductBase.create.mainInformation.vendorCode.item.label')}
              </Text>
            </HorizontalContainer>
            <TextField
              className={styles.inputCodesContainer}
              placeholder={t(
                'snackProductBase.create.mainInformation.vendorCode.input.placeholder',
              )}
              value={formik.values.vendorCode}
              status={formik.errors.vendorCode ? 'alert' : undefined}
              caption={formik.errors.vendorCode}
              onChange={({ value }) => formik.setFieldValue('vendorCode', value)}
            />
          </HorizontalContainer>
        </VerticalContainer>
      </ContentCard>
    );
  };

  const renderNutritionalValues = () => {
    return (
      <ContentCard className={styles.NutritionalValues}>
        <Text size="xl" weight="semibold">
          {t('snackProductBase.create.nutritionValue.title')}
        </Text>
        <VerticalContainer>
          <HorizontalContainer space="m" align="center">
            <HorizontalContainer className={styles.textContainer}>
              <Text size="m" view="primary">
                {t('snackProductBase.create.nutritionValue.calories.item.label')}
              </Text>
            </HorizontalContainer>
            <TextField
              className={styles.inputContainer}
              placeholder={t(
                'snackProductBase.create.nutritionValue.calories.input.placeholder.left',
              )}
              rightSide={t(
                'snackProductBase.create.nutritionValue.calories.input.placeholder.right',
              )}
              value={formik.values.calories !== null ? formik.values.calories.toString() : ''}
              status={formik.errors.calories ? 'alert' : undefined}
              caption={formik.errors.calories}
              onChange={({ value }) => formik.setFieldValue('calories', value)}
            />
          </HorizontalContainer>
          <HorizontalContainer space="m" align="center">
            <HorizontalContainer className={styles.textContainer}>
              <Text size="m" view="primary">
                {t('snackProductBase.create.nutritionValue.proteins.item.label')}
              </Text>
            </HorizontalContainer>
            <TextField
              className={styles.inputContainer}
              placeholder={t(
                'snackProductBase.create.nutritionValue.proteins.input.placeholder.left',
              )}
              rightSide={t(
                'snackProductBase.create.nutritionValue.proteins.input.placeholder.right',
              )}
              value={formik.values.proteins !== null ? formik.values.proteins.toString() : ''}
              status={formik.errors.proteins ? 'alert' : undefined}
              caption={formik.errors.proteins}
              onChange={({ value }) => formik.setFieldValue('proteins', value)}
            />
          </HorizontalContainer>
          <HorizontalContainer space="m" align="center">
            <HorizontalContainer className={styles.textContainer}>
              <Text size="m" view="primary">
                {t('snackProductBase.create.nutritionValue.fats.item.label')}
              </Text>
            </HorizontalContainer>
            <TextField
              className={styles.inputContainer}
              placeholder={t('snackProductBase.create.nutritionValue.fats.input.placeholder.left')}
              rightSide={t('snackProductBase.create.nutritionValue.fats.input.placeholder.right')}
              value={formik.values.fats !== null ? formik.values.fats.toString() : ''}
              status={formik.errors.fats ? 'alert' : undefined}
              caption={formik.errors.fats}
              onChange={({ value }) => formik.setFieldValue('fats', value)}
            />
          </HorizontalContainer>
          <HorizontalContainer space="m" align="center">
            <HorizontalContainer className={styles.textContainer}>
              <Text size="m" view="primary">
                {t('snackProductBase.create.nutritionValue.carbohydrates.item.label')}
              </Text>
            </HorizontalContainer>
            <TextField
              className={styles.inputContainer}
              placeholder={t(
                'snackProductBase.create.nutritionValue.carbohydrates.input.placeholder.left',
              )}
              rightSide={t(
                'snackProductBase.create.nutritionValue.carbohydrates.input.placeholder.right',
              )}
              value={
                formik.values.carbohydrates !== null ? formik.values.carbohydrates.toString() : ''
              }
              status={formik.errors.carbohydrates ? 'alert' : undefined}
              caption={formik.errors.carbohydrates}
              onChange={({ value }) => formik.setFieldValue('carbohydrates', value)}
            />
          </HorizontalContainer>
        </VerticalContainer>
      </ContentCard>
    );
  };

  const renderAnotherInformation = () => {
    return (
      <ContentCard className={styles.AnotherInformation}>
        <Text size="xl" weight="semibold">
          {t('snackProductBase.create.another.title')}
        </Text>
        <VerticalContainer className={styles.horizontalGap}>
          <HorizontalContainer space="m">
            <HorizontalContainer className={styles.textContainer}>
              <Text size="m" view="primary">
                {t('snackProductBase.create.another.compound.item.label')}
              </Text>
            </HorizontalContainer>
            <TextField
              placeholder={t('snackProductBase.create.another.compound.input.placeholder')}
              className={styles.inputContainer}
              size="m"
              type="textarea"
              rows={3}
              value={formik.values.compound}
              status={formik.errors.compound ? 'alert' : undefined}
              caption={formik.errors.compound}
              onChange={({ value }) => formik.setFieldValue('compound', value)}
            />
          </HorizontalContainer>
          <HorizontalContainer space="m">
            <HorizontalContainer className={styles.textContainer}>
              <Text size="m" view="primary">
                {t('snackProductBase.create.another.allergens.item.label')}
              </Text>
            </HorizontalContainer>
            <TextField
              placeholder={t('snackProductBase.create.another.allergens.input.placeholder')}
              className={styles.inputContainer}
              size="m"
              type="textarea"
              rows={3}
              value={formik.values.allergens}
              status={formik.errors.allergens ? 'alert' : undefined}
              caption={formik.errors.allergens}
              onChange={({ value }) => formik.setFieldValue('allergens', value)}
            />
          </HorizontalContainer>
          <HorizontalContainer space="m">
            <HorizontalContainer className={styles.textContainer}>
              <Text size="m" view="primary">
                {t('snackProductBase.create.another.item.label')}
              </Text>
            </HorizontalContainer>
            <TextField
              placeholder={t('snackProductBase.create.another.description.input.placeholder')}
              className={styles.inputContainer}
              size="m"
              type="textarea"
              rows={3}
              value={formik.values.description}
              status={formik.errors.description ? 'alert' : undefined}
              caption={formik.errors.description}
              onChange={({ value }) => formik.setFieldValue('description', value)}
            />
          </HorizontalContainer>
        </VerticalContainer>
      </ContentCard>
    );
  };

  const renderForms = () => {
    return (
      <VerticalContainer space="l" className={styles.content}>
        {renderMainInformation()}
        {renderNutritionalValues()}
        {renderAnotherInformation()}
      </VerticalContainer>
    );
  };

  const renderActions = () => {
    return (
      <Button
        className={styles.button}
        label={t('snackProductBase.create.add.button.label')}
        onClick={() => formik.handleSubmit()}
      />
    );
  };

  return (
    <DefaultFullScreenModal
      modalTitle={t('snackProductBase.create.title')}
      breadcrumbs={breadcrumbs}
      renderActions={renderActions}
      onClose={handleClose}
      className={styles.CreateSnackProduct}
      classNameHeader={styles.header}
      classNameActions={styles.actions}
    >
      {renderForms()}
    </DefaultFullScreenModal>
  );
};

export default CreateSnackProduct;
