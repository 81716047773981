import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconHamburgerSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M2.5 2.62C2.29289 2.62 2.125 2.78789 2.125 2.995C2.125 3.2021 2.29289 3.37 2.5 3.37H9.5C9.70711 3.37 9.875 3.2021 9.875 2.995C9.875 2.78789 9.70711 2.62 9.5 2.62H2.5Z" />
    <path d="M2.5 5.62C2.29289 5.62 2.125 5.78789 2.125 5.995C2.125 6.2021 2.29289 6.37 2.5 6.37H9.5C9.70711 6.37 9.875 6.2021 9.875 5.995C9.875 5.78789 9.70711 5.62 9.5 5.62H2.5Z" />
    <path d="M2.5 8.62C2.29289 8.62 2.125 8.78789 2.125 8.995C2.125 9.2021 2.29289 9.37 2.5 9.37H9.5C9.70711 9.37 9.875 9.2021 9.875 8.995C9.875 8.78789 9.70711 8.62 9.5 8.62H2.5Z" />
  </svg>
);

const IconHamburgerSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M3.33331 3.5C3.05717 3.5 2.83331 3.72386 2.83331 4C2.83331 4.27614 3.05717 4.5 3.33331 4.5H12.6666C12.9428 4.5 13.1666 4.27614 13.1666 4C13.1666 3.72386 12.9428 3.5 12.6666 3.5H3.33331Z" />
    <path d="M3.33331 7.5C3.05717 7.5 2.83331 7.72386 2.83331 8C2.83331 8.27614 3.05717 8.5 3.33331 8.5H12.6666C12.9428 8.5 13.1666 8.27614 13.1666 8C13.1666 7.72386 12.9428 7.5 12.6666 7.5H3.33331Z" />
    <path d="M3.33331 11.5C3.05717 11.5 2.83331 11.7239 2.83331 12C2.83331 12.2761 3.05717 12.5 3.33331 12.5H12.6666C12.9428 12.5 13.1666 12.2761 13.1666 12C13.1666 11.7239 12.9428 11.5 12.6666 11.5H3.33331Z" />
  </svg>
);

const IconHamburgerSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M5 5.25C4.58579 5.25 4.25 5.58579 4.25 6C4.25 6.41421 4.58579 6.75 5 6.75H19C19.4142 6.75 19.75 6.41421 19.75 6C19.75 5.58579 19.4142 5.25 19 5.25H5Z" />
    <path d="M5 11.25C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75H19C19.4142 12.75 19.75 12.4142 19.75 12C19.75 11.5858 19.4142 11.25 19 11.25H5Z" />
    <path d="M5 17.25C4.58579 17.25 4.25 17.5858 4.25 18C4.25 18.4142 4.58579 18.75 5 18.75H19C19.4142 18.75 19.75 18.4142 19.75 18C19.75 17.5858 19.4142 17.25 19 17.25H5Z" />
  </svg>
);

export const IconHamburger = createIcon({
  name: 'IconHamburger',
  s: IconHamburgerSizeS,
  m: IconHamburgerSizeM,
  xs: IconHamburgerSizeXS,
});
