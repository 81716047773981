import { FC, useRef, useState } from 'react';
import styles from './UserSetting.module.scss';
import { Avatar } from '@consta/uikit/Avatar';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { IconArrowDown } from '@consta/uikit/IconArrowDown';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { selectCabinet } from '../../../state/user/selectors';
import classNames from 'classnames';
import { cnMixFocus } from '@consta/uikit/MixFocus';
import ContextModal from '../../ContextModal';
import VerticalContainer from '../../VerticalContainer';
import { selectOrganization } from '../../../state/organization/selectors';
import ThemeMenuItem from './ThemeMenuItem';
import MenuItem from './MenuItem';
import HorizontalContainer from '../../HorizontalContainer';
import { ListDivider } from '@consta/uikit/ListCanary';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { defaultIconProps } from '../../../consts/defaultIconProps';
import { IconSignOut } from '../../../assets/icon/iconSignOut';
import { logoutAction } from '../../../state/organization/actions';
import LocaleMenuItem from './LocaleMenuItem';
import { UserSettingProps } from './types';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../pages/App';

/**
 * Меню настройки пользователя для верхнего меню
 */
const UserSetting: FC<UserSettingProps> = ({
  theme,
  language,
  onThemeChange,
  onLanguageChange,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const ref = useRef<HTMLButtonElement>(null);
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const user = useAppSelector(selectCabinet());
  const organization = useAppSelector(selectOrganization());

  if (!user)
    return (
      <Button
        label={t('components.userSettings.logOut.button.label')}
        onClick={() => dispatch(logoutAction())}
      />
    );

  const userLabel = `${user.surname} ${user.name}`;

  // Обработчики
  const handleOpenClick = () => {
    setIsOpenMenu(true);
  };

  const handleClose = () => {
    setIsOpenMenu(false);
  };

  const logOut = () => {
    dispatch(logoutAction());
  };

  //render методы
  const renderOrganizationTag = () =>
    organization && (
      <div className={styles.organizationTag}>
        <Avatar name={organization.name} />
        <Text size="s" className={styles.text}>
          {organization.name}
        </Text>
      </div>
    );

  const renderDropDown = () => (
    <ContextModal
      className={classNames(styles.dropDownMenu, isMobile && styles.isMobile)}
      currentRef={ref}
      isOpen={isOpenMenu}
      align="center"
      onClickOutside={handleClose}
    >
      <VerticalContainer className={styles.dropDownMenuContainer} space="2xl">
        <VerticalContainer space="xs" align="center">
          <Avatar className={styles.avatarContainer} name={userLabel} />
          <Text size="l">{userLabel}</Text>
          {renderOrganizationTag()}
        </VerticalContainer>
        <VerticalContainer space={0}>
          {/*<MenuItem
            label={t('components.userSettings.menuItem.personalAccount')}
            iconLeft={<IconProfileCircle size="m" {...defaultIconProps} />}
            onClick={() => navigate('/cabinet')}
          />*/}
          <ThemeMenuItem theme={theme} onThemeChange={onThemeChange} />
          <LocaleMenuItem language={language} onLanguageChange={onLanguageChange} />
          <ListDivider {...defaultIconProps} />
          <MenuItem
            label={t('components.userSettings.menuItem.logOut')}
            iconLeft={<IconSignOut size="m" {...defaultIconProps} />}
            onClick={logOut}
          />
        </VerticalContainer>
      </VerticalContainer>
    </ContextModal>
  );

  return (
    <>
      <button
        ref={ref}
        className={classNames(
          styles.UserSetting,
          isMobile && styles.isMobile,
          isOpenMenu && styles.isOpenMenu,
          cnMixFocus(),
        )}
        onClick={handleOpenClick}
      >
        <HorizontalContainer space="s" isAutoSpace align="center">
          <HorizontalContainer space="s" align="center">
            <Avatar
              size={isMobile ? 'm' : 'l'}
              className={classNames(isMobile && styles.isMobile, styles.avatar)}
              name={userLabel}
            />
            {!isMobile && (
              <Text className={styles.text} size="l" weight="regular">
                {userLabel}
              </Text>
            )}
          </HorizontalContainer>
          <IconArrowDown {...defaultIconProps} className={styles.icon} />
        </HorizontalContainer>
      </button>
      {renderDropDown()}
    </>
  );
};

export default UserSetting;
