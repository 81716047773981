import { FC, useState } from 'react';
import { ProductListItemDTO } from '../../../../types/serverInterface/productDTO';
import styles from './ProductItem.module.scss';
import classNames from 'classnames';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import TableContextMenu from '../../../../components/TableContextMenu';
import { IconInfo } from '@consta/uikit/IconInfo';
import { IconWorldStroked } from '@consta/uikit/IconWorld';
import { IconTrash } from '@consta/uikit/IconTrash';
import { IconKebab } from '@consta/uikit/IconKebab';
import { useAppDispatch } from '../../../../app/hooks/store';
import { deleteProductAction } from '../../../../state/productBase/actions';
import { TableContextMenuItem } from '../../../../components/TableContextMenu/TableContextMenu';
import DeleteModal from '../../../../components/DeleteModal';
import ProductInfo from '../ProductInfo';
import ProductLocale from '../ProductLocale';

/**
 * Свойства компонента ProductItem
 */
type ProductItemProps = {
  /**
   * Продукт
   */
  product: ProductListItemDTO;
  /**
   * Индекс элемента в списке продуктов в линейке продукта
   */
  index: number;
  /**
   * id организации
   */
  organizationId: number | null;
  /**
   * id линейки продуктов
   */
  productLineId: number;
  /**
   * Возможность пользователя редактировать глобальные сущности
   */
  isAllowedGlobalEdit: boolean;
};

/**
 * Возможные элементы контекстного меню продукта
 */
enum ProductContextMenuItems {
  /**
   * Информация
   */
  INFO = 'INFO',
  /**
   * Локализация
   */
  LOCALE = 'LOCALE',
  /**
   * Удаление
   */
  DELETE = 'DELETE',
}

/**
 * Список элементов для контекстного меню продукта с редактированием
 */
const productContextMenuItemsWithEdit = [
  {
    name: ProductContextMenuItems.INFO,
    label: 'productBase.product.contextMenu.INFO',
    iconLeft: IconInfo,
    group: 1,
  },
  {
    name: ProductContextMenuItems.LOCALE,
    label: 'productBase.product.contextMenu.LOCALE',
    iconLeft: IconWorldStroked,
    group: 1,
  },
  {
    name: ProductContextMenuItems.DELETE,
    label: 'productBase.product.contextMenu.DELETE',
    iconLeft: IconTrash,
    group: 2,
  },
];

/**
 * Список элементов для контекстного меню продукта без редактирования
 */
const productContextMenuItemsWithoutEdit = [
  {
    name: ProductContextMenuItems.INFO,
    label: 'productBase.product.contextMenu.INFO',
    iconLeft: IconInfo,
    group: 1,
  },
];

/**
 * Элемент списка продуктов в линейке продуктов
 */
const ProductItem: FC<ProductItemProps> = ({
  product,
  index,
  organizationId,
  productLineId,
  isAllowedGlobalEdit,
}) => {
  const dispatch = useAppDispatch();

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isLocaleOpen, setIsLocaleOpen] = useState(false);

  const isAllowedEdit = !!product.organizationId || isAllowedGlobalEdit;

  const isZebra = !(index % 2);

  // Обработчики
  const handleInfoClick = () => {
    setIsInfoOpen(true);
  };

  const handleInfoClose = () => {
    setIsInfoOpen(false);
  };

  const handleDeleteClick = () => {
    setIsDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setIsDeleteOpen(false);
  };

  const handleDelete = () => {
    setIsDeleteLoading(true);
    isAllowedEdit &&
      dispatch(deleteProductAction(product.id, productLineId, organizationId)).then(() => {
        setIsDeleteLoading(false);
        setIsDeleteOpen(false);
      });
  };

  const handleLocaleClick = () => {
    setIsLocaleOpen(true);
  };

  const handleLocaleClose = () => {
    setIsLocaleOpen(false);
  };

  const handleContextMenuItemClick = (item: TableContextMenuItem) => {
    switch (item.name) {
      case ProductContextMenuItems.INFO:
        handleInfoClick();
        return;
      case ProductContextMenuItems.LOCALE:
        handleLocaleClick();
        return;
      case ProductContextMenuItems.DELETE:
        handleDeleteClick();
        return;
    }
  };

  // render методы
  const renderInfo = () =>
    isInfoOpen && (
      <ProductInfo
        isOpen={isInfoOpen}
        productId={product.id}
        productLineId={productLineId}
        organizationId={organizationId}
        isAllowedEdit={isAllowedEdit}
        onClose={handleInfoClose}
      />
    );

  const renderDeleteProduct = () =>
    isDeleteOpen && (
      <DeleteModal
        isOpen={isDeleteOpen}
        isDeleteLoading={isDeleteLoading}
        title={`Удалить продукт ${product.name}`}
        description={`Вы уверены, что хотите удалить продукт ${product.name}`}
        withConfirmDeletion={false}
        onClose={handleDeleteClose}
        onDelete={handleDelete}
      />
    );

  const renderLocale = () =>
    isLocaleOpen && (
      <ProductLocale
        isOpen={isLocaleOpen}
        productLineId={productLineId}
        organizationId={organizationId}
        productId={product.id}
        productName={product.name}
        onClose={handleLocaleClose}
      />
    );

  return (
    <div className={classNames(styles.productListItem, isZebra && styles.zebra)}>
      <Text size="l">{product.name}</Text>
      <TableContextMenu
        className={classNames(styles.productLineContextMenu, isZebra && styles.zebra)}
        items={isAllowedEdit ? productContextMenuItemsWithEdit : productContextMenuItemsWithoutEdit}
        buttonIcon={IconKebab}
        onItemClick={handleContextMenuItemClick}
      />
      {renderInfo()}
      {isAllowedEdit && renderDeleteProduct()}
      {isAllowedEdit && renderLocale()}
    </div>
  );
};

export default ProductItem;
