import BaseTableDataRow from './BaseTableDataRow';
import BaseTableDataLoader from './BaseTableDataLoader';

import { BaseTableDataProps } from './types';

import classNames from 'classnames';
import styles from './BaseTableData.module.scss';

/**
 * Данные базовой таблицы без стилей
 */
const BaseTableData = <T,>({
  isLoading,

  rowClassName,
  tableDataClassName = '',
  gridContainerClassName = '',
  baseTableDataLoaderClassName,

  rowHeight,

  rows,
  columns,

  getRowClassName,
  onRowClick,
}: BaseTableDataProps<T>) => {
  // render методы
  const renderRows = () =>
    rows.map((row, index) => (
      <BaseTableDataRow
        key={index}
        rowClassName={rowClassName}
        gridContainerClassName={gridContainerClassName}
        rowIndex={index}
        columns={columns}
        row={row}
        getRowClassName={getRowClassName}
        onRowClick={onRowClick}
      />
    ));

  if (isLoading)
    return (
      <BaseTableDataLoader
        rowHeight={rowHeight}
        baseTableDataLoaderClassName={baseTableDataLoaderClassName}
      />
    );

  return (
    <div className={classNames(styles.BaseTableData, gridContainerClassName, tableDataClassName)}>
      {renderRows()}
    </div>
  );
};

export default BaseTableData;
