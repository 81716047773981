import * as Yup from 'yup';
import { SignInVendistaDTO } from '../../../../../types/serverInterface/vendistaDTO';

/**
 * Cхема валидации формы входа в Vendista
 */
export const vendistaSignInFormValidationScheme: Yup.ObjectSchema<SignInVendistaDTO> =
  Yup.object().shape({
    login: Yup.string().required(),
    password: Yup.string().required(),
    organizationId: Yup.number().required(),
  });
