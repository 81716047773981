import * as Yup from 'yup';
import { EditSnackMachineStorageSnackCell } from '../../../../types/serverInterface/snackMachineStorage/editSnackMachineStorage';

/**
 * Валидация изменения productId и snackId в одной ячейке на странице наполнения снек автомата
 */
const editSnackFillingCell: Yup.ObjectSchema<EditSnackMachineStorageSnackCell> = Yup.object().shape(
  {
    id: Yup.number().required(),
    snackBrandId: Yup.number().nullable().defined(),
    snackProductId: Yup.number()
      .nullable()
      .defined()
      .test(function (value) {
        const { snackBrandId } = this.parent;
        return (
          (value === null && snackBrandId === null) || (value !== null && snackBrandId !== null)
        );
      }),

    snackBrandName: Yup.string().nullable(),
    snackProductName: Yup.string().nullable(),
    cellNumber: Yup.number().nullable(),
    rowNumber: Yup.number().nullable(),
    volume: Yup.number().nullable(),
    maxVolume: Yup.number().nullable(),
    warningVolume: Yup.number().nullable(),
    minVolume: Yup.number().nullable(),
    isManuallyDisabled: Yup.boolean().nullable(),
    isDisabledDueToMerge: Yup.boolean().nullable(),
    wasExtraTwist: Yup.boolean().nullable(),
    didNotGiveOutTheGood: Yup.boolean().nullable(),
    motorNumber: Yup.number().nullable(),
    motorIds: Yup.array().of(Yup.number().required()).nullable(),
    expirationTimer: Yup.string().nullable(),
  },
);

/**
 * Валидация изменения productId и snackId во всей схеме на странице наполнения снек автомата
 */
export const editSnackFillingValidationScheme = Yup.array().of(
  Yup.array().of(editSnackFillingCell),
);
