import { SnackCellPriceWithCellId } from './types';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import { selectSnackMachineStorage } from '../../../../state/machineControl/selectors';
import { useEffect, useMemo } from 'react';
import { getSnackMachineStorageAction } from '../../../../state/machineControl/actions';
import { getOrganizationId } from '../../../../helpers/getOrganizationId';
import { selectSnackBrandList } from '../../../../state/productBase/selectors';
import { getSnackBrandListAction } from '../../../../state/productBase/actions';
import { getSnackProductsWithPrices } from './helpers';
import { EditSnackMachineStorageSnackCell } from '../../../../types/serverInterface/snackMachineStorage/editSnackMachineStorage';

/**
 * Преобразование списка товаров для снек автомата
 *
 * @param machineId id автомата
 */
export const useMachineSnackFilling = (
  machineId: number,
): [EditSnackMachineStorageSnackCell[][], Record<number, SnackCellPriceWithCellId>] => {
  const dispatch = useAppDispatch();

  const { state: snackMachineStorage } = useAppSelector(selectSnackMachineStorage());

  const snackCells = [...(snackMachineStorage?.snackCells || [])].sort(
    (a, b) => (a?.cellNumber || 0) - (b?.cellNumber || 0),
  );

  useEffect(() => {
    if (machineId) {
      dispatch(getSnackMachineStorageAction(machineId));
    }
  }, [dispatch, machineId]);

  const snackCellsGroupedByRowNumber = useMemo(() => {
    const maxRowNumber =
      snackCells?.reduce((max, cell) => Math.max(max, cell.rowNumber || 0), 0) || 0;

    const cellsByRow: EditSnackMachineStorageSnackCell[][] = Array.from(
      { length: maxRowNumber + 1 },
      () => [],
    );

    (snackCells || []).forEach((cell) => {
      const {
        rowNumber,
        snackBrandId,
        snackProductId,
        id,
        maxVolume,
        warningVolume,
        minVolume,
        isManuallyDisabled,
        cellNumber,
        motorNumber,
        motorIds,
        volume,
        snackBrandName,
        snackProductName,
        didNotGiveOutTheGood,
        wasExtraTwist,
        isDisabledDueToMerge,
        expirationTimer,
      } = cell;

      const editCell: EditSnackMachineStorageSnackCell = {
        rowNumber,
        snackBrandId,
        snackProductId,
        id,
        maxVolume,
        warningVolume,
        minVolume,
        isManuallyDisabled,
        cellNumber,
        motorNumber,
        motorIds,
        volume,
        snackBrandName,
        snackProductName,
        didNotGiveOutTheGood,
        wasExtraTwist,
        isDisabledDueToMerge,
        expirationTimer,
      };

      rowNumber && cellsByRow[rowNumber].push(editCell);
    });

    return cellsByRow.filter((row) => row.length > 0);
  }, [snackCells]);

  const snackProductsWithPrices = useMemo(() => {
    return getSnackProductsWithPrices(snackMachineStorage);
  }, [snackMachineStorage]);

  return [snackCellsGroupedByRowNumber, snackProductsWithPrices];
};

/**
 * Получение списка брендов для снек автомата
 */
export const useSnackBrandList = () => {
  const dispatch = useAppDispatch();
  const organizationId = getOrganizationId();
  const { state: snackBrandList } = useAppSelector(selectSnackBrandList());

  useEffect(() => {
    organizationId && dispatch(getSnackBrandListAction(organizationId));
  }, [dispatch, organizationId]);

  return useMemo(() => {
    return snackBrandList || { brandList: [] };
  }, [snackBrandList]);
};
