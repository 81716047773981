import { DefaultFullScreenProps } from './types';
import { FC } from 'react';
import { Modal } from '@consta/uikit/Modal';
import classNames from 'classnames';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconClose } from '../../assets/icon/iconClose';
import HorizontalContainer from '../HorizontalContainer';
import styles from './DefaultFullScreenFilters.module.scss';
import VerticalContainer from '../VerticalContainer';

/**
 * Модальное окно для фильтров продаж организации
 */
const DefaultFullScreenFilters: FC<DefaultFullScreenProps> = ({
  className,
  isOpen = true,
  modalTitle,
  position = 'top',
  onClose,
  renderActions,
  children,
}) => {
  const showCloseButton = Boolean(onClose);

  // Обработчики
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      position={position}
      isOpen={isOpen}
      onEsc={handleClose}
      onClickOutside={handleClose}
      className={classNames(styles.DefaultFullScreenFilters, className)}
    >
      {showCloseButton && (
        <HorizontalContainer justify="end" className={styles.horizontalContainer}>
          <Button
            onlyIcon
            view="ghost"
            form="round"
            size="m"
            iconLeft={IconClose as any}
            onClick={handleClose}
          />
        </HorizontalContainer>
      )}
      <VerticalContainer space="3xl" className={styles.verticalContainer}>
        <HorizontalContainer align="center" className={styles.horizontalContainerTitle}>
          <Text size="3xl" weight="semibold">
            {modalTitle}
          </Text>
          {renderActions && <>{renderActions()}</>}
        </HorizontalContainer>

        <div className={styles.children}>{children}</div>
      </VerticalContainer>
    </Modal>
  );
};

export default DefaultFullScreenFilters;
