import { AbstractApiModule } from '../../abstractApiModule';
import {
  AccessTokenResponse,
  RegistrationDTO,
  RegistrationInfoDTO,
} from '../../../../types/serverInterface/authDTO';
import { organizationBaseUrl, authKKBaseUrl, baseUrlFront } from '../../../../consts';

export class AuthModule extends AbstractApiModule {
  async getAccessTokenPKCE(code: string): Promise<AccessTokenResponse> {
    try {
      const response = await fetch(
        `${authKKBaseUrl}/realms/shaker-realm/protocol/openid-connect/token`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': '*/*',
            'Accept-Encoding': 'gzip, deflate, br',
          },
          body: new URLSearchParams({
            grant_type: 'authorization_code',
            client_id: 'shaker-client',
            code_verifier: 'sNEvZ9cYRETkZAIE5CYVKLyh4-NCDyRqG9dH3UxLahE',
            code,
            redirect_uri: `${baseUrlFront}/auth/redirect/`,
          }),
        },
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Error getting access token:', error);
      throw error;
    }
  }

  /**
   * Регистрация
   *
   * @param data данные формы регистрации
   * @param hash hash из приглашения
   * @param csrf csrf токен для регистрации
   */
  registration({ data, hash }: { data: RegistrationDTO; hash: string }) {
    return this.request.post<RegistrationDTO, void>(
      `${organizationBaseUrl}/user/registration-by-invitation/${hash}`,
      data,
    );
  }

  /**
   * Получение информации для регистрации
   *
   * @param hash hash из приглашения
   */
  fetchRegistrationInfo(hash: string) {
    return this.request.get<void, RegistrationInfoDTO>(
      `${organizationBaseUrl}/user/registration-by-invitation/${hash}`,
    );
  }
}
