import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';
import { SVGProps } from 'react';

const IconSearchSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <g clipPath="url(#clip0_34249_1214)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 0.125C2.80761 0.125 0.625 2.30761 0.625 5C0.625 7.69239 2.80761 9.875 5.5 9.875C8.19239 9.875 10.375 7.69239 10.375 5C10.375 2.30761 8.19239 0.125 5.5 0.125ZM1.375 5C1.375 2.72183 3.22183 0.875 5.5 0.875C7.77817 0.875 9.625 2.72183 9.625 5C9.625 7.27817 7.77817 9.125 5.5 9.125C3.22183 9.125 1.375 7.27817 1.375 5Z"
      />
      <path d="M9.7652 8.73488C9.61875 8.58844 9.38132 8.58843 9.23487 8.73488C9.08842 8.88132 9.08842 9.11876 9.23487 9.26521L11.2348 11.2652C11.3813 11.4116 11.6187 11.4116 11.7651 11.2652C11.9116 11.1187 11.9116 10.8813 11.7651 10.7349L9.7652 8.73488Z" />
    </g>
    <defs>
      <clipPath id="clip0_34249_1214">
        <rect width="12" height="12" />
      </clipPath>
    </defs>
  </svg>
);

const IconSearchSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <g clipPath="url(#clip0_34249_1215)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33325 0.166626C3.7434 0.166626 0.833252 3.07678 0.833252 6.66663C0.833252 10.2565 3.7434 13.1666 7.33325 13.1666C10.9231 13.1666 13.8333 10.2565 13.8333 6.66663C13.8333 3.07678 10.9231 0.166626 7.33325 0.166626ZM1.83325 6.66663C1.83325 3.62906 4.29569 1.16663 7.33325 1.16663C10.3708 1.16663 12.8333 3.62906 12.8333 6.66663C12.8333 9.70419 10.3708 12.1666 7.33325 12.1666C4.29569 12.1666 1.83325 9.70419 1.83325 6.66663Z"
      />
      <path d="M13.0202 11.6465C12.8249 11.4512 12.5083 11.4512 12.3131 11.6465C12.1178 11.8417 12.1178 12.1583 12.3131 12.3536L14.9797 15.0202C15.1749 15.2155 15.4915 15.2155 15.6868 15.0202C15.882 14.8249 15.882 14.5084 15.6868 14.3131L13.0202 11.6465Z" />
    </g>
    <defs>
      <clipPath id="clip0_34249_1215">
        <rect width="16" height="16" />
      </clipPath>
    </defs>
  </svg>
);

const IconSearchSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 0.25C5.61522 0.25 1.25 4.61522 1.25 10C1.25 15.3848 5.61522 19.75 11 19.75C16.3848 19.75 20.75 15.3848 20.75 10C20.75 4.61522 16.3848 0.25 11 0.25ZM2.75 10C2.75 5.44365 6.44365 1.75 11 1.75C15.5563 1.75 19.25 5.44365 19.25 10C19.25 14.5563 15.5563 18.25 11 18.25C6.44365 18.25 2.75 14.5563 2.75 10Z"
    />
    <path d="M19.5304 17.4698C19.2375 17.1769 18.7626 17.1769 18.4697 17.4698C18.1768 17.7626 18.1768 18.2375 18.4697 18.5304L22.4696 22.5304C22.7625 22.8233 23.2374 22.8233 23.5303 22.5304C23.8232 22.2375 23.8232 21.7626 23.5303 21.4697L19.5304 17.4698Z" />
  </svg>
);

export const IconSearch = createIcon({
  name: 'IconSearch',
  m: IconSearchSizeM,
  s: IconSearchSizeS,
  xs: IconSearchSizeXS,
});
