import React, { FC, useMemo, useState } from 'react';
import styles from './Notification.module.scss';
import { useAppSelector } from '../../app/hooks/store';
import { selectNotificationsList } from '../../state/user/selectors';
import { SnackBar } from '@consta/uikit/SnackBar';
import { IconAlert } from '../../assets/icon/iconAlert';
import { useTranslation } from 'react-i18next';

/**
 * Компонент списка уведомлений
 */
const NotificationsList: FC = () => {
  const { t } = useTranslation();

  const notificationsList = useAppSelector(selectNotificationsList());

  const [closedNotificationIndex, setClosedNotificationIndex] = useState<Record<number, boolean>>(
    {},
  );

  const formatedNotificationList = useMemo(
    () =>
      notificationsList
        .map((item, index) => ({ ...item, index }))
        .filter(({ index }) => !closedNotificationIndex?.[index]),
    [notificationsList, closedNotificationIndex],
  );

  return (
    <div className={styles.list}>
      <SnackBar
        items={formatedNotificationList}
        getItemKey={({ index }) => index}
        getItemStatus={({ status }) => status}
        getItemMessage={({ text = '' }) => t(text)}
        getItemAutoClose={() => 5}
        getItemIcon={() => IconAlert as any}
        getItemShowProgress={() => 'line'}
        onItemAutoClose={({ index }) => {
          setClosedNotificationIndex((prevState) => ({ ...prevState, [index]: true }));
        }}
        onItemClose={({ index }) => {
          setClosedNotificationIndex((prevState) => ({ ...prevState, [index]: true }));
        }}
      />
    </div>
  );
};

export default NotificationsList;
