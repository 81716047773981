import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks/store';
import { useTranslation } from 'react-i18next';
import { selectCellPurposeList } from '../../../state/productBase/selectors';
import {
  deleteCellPurposeAction,
  getCellPurposeListAction,
} from '../../../state/productBase/actions';
import styles from './CellPurpose.module.scss';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { TextField } from '@consta/uikit/TextField';
import { IconSearch } from '@consta/uikit/IconSearch';
import { IconWorldStroked } from '@consta/uikit/IconWorld';
import { IconEdit } from '@consta/uikit/IconEdit';
import { IconTrash } from '@consta/uikit/IconTrash';
import CellPurposeForm from './CellPurposeForm';
import EditCellPurpose from './CellPurposeForm/EditCellPurpose';
import DeleteCellPurpose from './DeleteCellPurpose';
import { Loader } from '@consta/uikit/Loader';
import CellPurposeLocale from './CellPurposeLocale';
import { CellPurposeDTO } from '../../../types/serverInterface/cellPurpose';
import TableCardWithBackground from '../../../components/TableCardWithBackground';

/**
 * Базовый компонент назначения контейнера
 */
const CellPurpose: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const cellPurposeList = useAppSelector(selectCellPurposeList());

  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [editableId, setEditableId] = useState<number | null>(null);
  const [deletableId, setDeletableId] = useState<number | null>(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [cellPurposeLocaleId, setCellPurposeLocaleId] = useState<number | null>(null);

  const filterCellPurpose = useMemo(() => {
    return cellPurposeList.filter((cellPurpose) =>
      cellPurpose.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [searchQuery, cellPurposeList]);

  useEffect(() => {
    dispatch(getCellPurposeListAction()).finally(() => setIsLoading(false));
  }, [dispatch]);

  // Обработчики
  // TODO: дублирование кода
  const handleAddCellPurposeButtonClick = () => {
    setIsCreateOpen(true);
  };

  const handleAddCellPurposeClose = () => {
    setIsCreateOpen(false);
  };

  const handleSearchChange = ({ value }: { value: string | null }) => {
    setSearchQuery(value || '');
  };

  const handleEditClick = (id: number) => () => {
    setEditableId(id);
  };

  const handleEditClose = () => {
    setEditableId(null);
  };

  const handleDeleteClick = (id: number) => () => {
    setDeletableId(id);
  };

  const handleCancelDeleteClick = () => {
    setDeletableId(null);
  };

  const handleDelete = () => {
    setIsDeleteLoading(true);
    deletableId &&
      dispatch(deleteCellPurposeAction(deletableId)).finally(() => {
        setIsDeleteLoading(false);
      });
  };

  const handleLocaleClick = (id: number) => () => {
    setCellPurposeLocaleId(id);
  };

  const handleLocaleClose = () => {
    setCellPurposeLocaleId(null);
  };

  // render методы
  const renderAction = () => (
    <div className={styles.actions}>
      <Button
        label={t('productBaseConfig.cellPurpose.add.button.label')}
        iconLeft={IconAdd as any}
        onClick={handleAddCellPurposeButtonClick}
      />
      <TextField
        width="full"
        form="round"
        leftSide={IconSearch as any}
        value={searchQuery}
        onChange={handleSearchChange}
      />
    </div>
  );

  const renderRowAction = ({ id }: CellPurposeDTO) => (
    <div className={styles.rowActions}>
      <Button view="ghost" iconLeft={IconWorldStroked as any} onClick={handleLocaleClick(id)} />
      <Button view="ghost" iconLeft={IconEdit as any} onClick={handleEditClick(id)} />
      <Button view="ghost" iconLeft={IconTrash as any} onClick={handleDeleteClick(id)} />
    </div>
  );

  const renderCreateForm = () => (
    <CellPurposeForm type="create" isOpen={isCreateOpen} onClose={handleAddCellPurposeClose} />
  );

  const renderEditForm = () =>
    editableId && <EditCellPurpose cellPurposeId={editableId} onClose={handleEditClose} />;

  const renderDeleteModal = () =>
    deletableId && (
      <DeleteCellPurpose
        cellPurposeId={deletableId}
        isDeleteLoading={isDeleteLoading}
        onClose={handleCancelDeleteClick}
        onDelete={handleDelete}
      />
    );

  const renderCellPurposeLocale = () =>
    cellPurposeLocaleId && (
      <CellPurposeLocale
        isOpen={!!cellPurposeLocaleId}
        cellPurposeId={cellPurposeLocaleId}
        onClose={handleLocaleClose}
      />
    );

  if (isLoading) return <Loader />;

  return (
    <div>
      {renderCreateForm()}
      {renderEditForm()}
      {renderDeleteModal()}
      {renderCellPurposeLocale()}
      {renderAction()}
      <TableCardWithBackground
        rows={filterCellPurpose}
        columns={{
          name: {
            key: 'name',
            title: t('productBaseConfig.cellPurpose.name.table.column.title'),
            alignment: 'left',
            getItemLabel: (data) => data.name,
          },
          id: {
            key: 'id',
            title: t('productBaseConfig.cellPurpose.action.table.column.title'),
            alignment: 'right',
            renderCell: renderRowAction,
          },
        }}
      />
    </div>
  );
};

export default CellPurpose;
