/**
 * Преобразует массив объектов в объект (map), где ключом является `id` объекта.
 *
 * @param array - Массив объектов, каждый из которых должен содержать поле `id`.
 * @returns Объект (map), где ключи — значения `id` из объектов массива, а значения — сами объекты.
 */
export const getMapById = <T extends { id: number }>(array: T[]): Record<number, T> => {
  return (array || []).reduce(
    (map, item) => {
      map[item.id] = item;
      return map;
    },
    {} as Record<number, T>,
  );
};
