import { errorHandler, NotificationType } from '../handlers';
import { createSlice, isRejected } from '@reduxjs/toolkit';
import {
  checkVendistaTokenThunk,
  deleteVendistaSettingThunk,
  editMachineVendistaSettingThunk,
  getMachineVendistaSettingListByOrganizationIdThunk,
  getMachineVendistaSettingThunk,
  signInVendistaThunk,
} from './thunk';
import {
  CheckVendistaTokenDTO,
  MachineVendistaSettingDTO,
  MachineVendistaSettingListItem,
} from '../../types/serverInterface/vendistaDTO';

type StateItemType<T> = {
  state: T extends [] ? T : T | null;
  isLoading: boolean;
  isReject: boolean;
};

export type VendistaState = {
  vendista: StateItemType<CheckVendistaTokenDTO>;
  vendistaMachineSetting: StateItemType<MachineVendistaSettingDTO>;
  machineVendistaSettingList: StateItemType<MachineVendistaSettingListItem[]>;
  notifications: NotificationType[];
};

const initialState: VendistaState = {
  vendista: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  vendistaMachineSetting: {
    state: null,
    isLoading: false,
    isReject: false,
  },
  machineVendistaSettingList: {
    state: [],
    isLoading: false,
    isReject: false,
  },
  notifications: [],
};

/**
 * Добавление уведомления
 *
 * @param state состояние
 * @param notification новое уведомление
 */
const addNotification = (state: VendistaState) => (notification: NotificationType) => {
  const arr = [...state.notifications];
  arr.push(notification);

  state.notifications = arr;
};

export const vendistaSlice = createSlice({
  name: 'vendista',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // signInVendistaThunkThunk
    builder.addCase(signInVendistaThunk.rejected, (state, action) => {
      state.notifications.push({
        text: 'integration.vendista.signIn.error.text',
        status: 'alert',
      });
    });

    // checkVendistaTokenThunk
    builder.addCase(checkVendistaTokenThunk.pending, (state) => {
      state.vendista.isLoading = true;
      state.vendista.isReject = false;
    });

    builder.addCase(checkVendistaTokenThunk.rejected, (state) => {
      state.vendista.isLoading = false;
      state.vendista.isReject = true;
    });

    builder.addCase(checkVendistaTokenThunk.fulfilled, (state, action) => {
      state.vendista.isLoading = false;
      state.vendista.state = action.payload;
    });

    // getMachineVendistaSettingThunk
    builder.addCase(getMachineVendistaSettingThunk.pending, (state) => {
      state.vendistaMachineSetting.isLoading = true;
      state.vendistaMachineSetting.isReject = false;
    });

    builder.addCase(getMachineVendistaSettingThunk.rejected, (state) => {
      state.vendistaMachineSetting.isLoading = false;
      state.vendistaMachineSetting.isReject = true;
    });

    builder.addCase(getMachineVendistaSettingThunk.fulfilled, (state, action) => {
      state.vendistaMachineSetting.isLoading = false;
      state.vendistaMachineSetting.state = action.payload;
    });

    // getMachineVendistaSettingListByOrganizationIdThunk
    builder.addCase(getMachineVendistaSettingListByOrganizationIdThunk.pending, (state) => {
      state.machineVendistaSettingList.isLoading = true;
      state.machineVendistaSettingList.isReject = false;
    });

    builder.addCase(getMachineVendistaSettingListByOrganizationIdThunk.rejected, (state) => {
      state.machineVendistaSettingList.isLoading = false;
      state.machineVendistaSettingList.isReject = true;
    });

    builder.addCase(
      getMachineVendistaSettingListByOrganizationIdThunk.fulfilled,
      (state, action) => {
        state.machineVendistaSettingList.isLoading = false;
        state.machineVendistaSettingList.state = action.payload;
      },
    );

    // editMachineVendistaSettingThunk
    builder.addCase(editMachineVendistaSettingThunk.rejected, (state, action) => {
      console.log('action, ', action);

      // state.notifications.push({
      //   header: {
      //     key: '',
      //     value: '',
      //   },
      //   description: {
      //     key: '',
      //     value: '',
      //   },
      //   status: 'alert',
      // });
    });

    builder.addMatcher(
      isRejected(
        checkVendistaTokenThunk,
        getMachineVendistaSettingThunk,
        deleteVendistaSettingThunk,
        getMachineVendistaSettingListByOrganizationIdThunk,
      ),
      (state, action) => {
        errorHandler(action)(addNotification(state));
      },
    );
  },
});

export const vendistaReducer = vendistaSlice.reducer;
