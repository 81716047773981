import { useExportFromExcel } from './useExportFromExcel';
import { PromoCodeListFiltersReq } from '../../../../types/serverInterface/promoCodeDTO';
import { useExportPromoCodeFromClickBoard } from './useExportPromoCodeFromClickBoard';
import { Pagination } from '../../../../types/types';

export const useExportPromoCode = (filters: {
  organizationId: number;
  filters: PromoCodeListFiltersReq;
  pagination: Pagination;
  t: (key: string) => string;
}) => {
  const { handleExcelExportClick } = useExportFromExcel(filters);
  const { handleExportFromClickBoardClick } = useExportPromoCodeFromClickBoard(filters);

  return { handleExcelExportClick, handleExportFromClickBoardClick };
};
