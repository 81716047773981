import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CheckVendistaTokenDTO,
  EditMachineVendistaSettingDTO,
  MachineVendistaSettingDTO,
  MachineVendistaSettingListItem,
  SignInVendistaDTO,
} from '../../types/serverInterface/vendistaDTO';
import { api } from '../../app/api';

/**
 * Авторизация Vendista
 *
 * @param data данные формы авторизации
 */
export const signInVendistaThunk = createAsyncThunk<void, SignInVendistaDTO>(
  'signInVendista',
  async (data) => {
    return await api.vendista.signInVendista(data);
  },
);

/**
 * Получение информации о состоянии токена vendista
 *
 * @param organizationId id организации
 */
export const checkVendistaTokenThunk = createAsyncThunk<CheckVendistaTokenDTO, number>(
  'checkVendistaToken',
  async (organizationId) => {
    return await api.vendista.checkVendistaToken(organizationId);
  },
);

/**
 * Удаление интеграции Vendista организации
 *
 * @param organizationId id организации
 */
export const deleteVendistaSettingThunk = createAsyncThunk<void, number>(
  'deleteVendistaSetting',
  async (organizationId) => {
    return await api.vendista.deleteVendistaSetting(organizationId);
  },
);

/**
 * Получение номера терминала vendista автомата
 *
 * @param organizationId id организации
 * @param machineId id автомата
 */
export const getMachineVendistaSettingThunk = createAsyncThunk<
  MachineVendistaSettingDTO,
  { organizationId: number; machineId: number }
>('getMachineVendistaSetting', async ({ organizationId, machineId }) => {
  return await api.vendista.getMachineVendistaSetting(organizationId, machineId);
});

/**
 * Получение списка автоматов с терминалом vendista организации
 *
 * @param organizationId id организации
 */
export const getMachineVendistaSettingListByOrganizationIdThunk = createAsyncThunk<
  MachineVendistaSettingListItem[],
  number
>('getMachineVendistaSettingListByOrganizationId', async (organizationId) => {
  return await api.vendista.getMachineVendistaSettingListByOrganizationId(organizationId);
});

/**
 * Изменения номера терминала vendista автомата
 *
 * @param machineId id автомата
 * @param data данные формы изменения настроек vendista автомата
 */
export const editMachineVendistaSettingThunk = createAsyncThunk<
  number,
  { organizationId: number; machineId: number; data: EditMachineVendistaSettingDTO }
>('editMachineVendistaSetting', async ({ organizationId, machineId, data }) => {
  return await api.vendista.editMachineVendistaSetting(organizationId, machineId, data);
});
