import VerticalContainer from '../../../../components/VerticalContainer';
import ContentCard from '../../../../components/ContentCard';
import { Combobox } from '@consta/uikit/Combobox';
import DefaultFullScreenFilters from '../../../../components/DefaultFullScreenFilters';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import { useNavigate } from 'react-router-dom';
import { useOrganizationSaleFilters } from './hook';
import { getOrganizationId } from '../../../../helpers/getOrganizationId';
import styles from './OrganizationSalesFilters.module.scss';
import { useFormik } from 'formik';
import { initialValuesOrganizationSaleFilters } from './consts';
import { organizationSaleFiltersValidationScheme } from './organizationSaleFiltersValidationScheme';
import { SaleMethod } from '../../../../types/serverInterface/machineDTO';
import { BrandDTO } from '../../../../types/serverInterface/brandDTO';
import { Slider } from '@consta/uikit/Slider';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { ChoiceGroup } from '@consta/uikit/ChoiceGroup';
import { TextField } from '@consta/uikit/TextField';
import { DatePicker, DatePickerPropValue } from '@consta/uikit/DatePicker';
import { IconCalendar } from '../../../../assets/icon/iconCalendar';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DiscountTypePromoCodeFilterEnum,
  UsagePromoCodeEnum,
} from '../../../PromoCodePage/PromoCodeList/types';
import {
  TransactionStatus,
  VendistaTokenStatus,
} from '../../../../types/serverInterface/vendistaDTO';
import { getLimitedInputNumberOrNull } from '../../../../helpers/ inputHelpers';
import { OrganizationSaleFiltersProps } from './types';
import {
  SaleAndTransactionFilterInfo,
  SalesAndTransactionsFilter,
} from '../../../../types/serverInterface/OrganizationSalesDTO';
import { filterInfoInitialValue } from '../OrganizationSaleList';

const minValue = 0;
const maxDiscountPercent = 100;
const maxDiscountFixed = 500;
const maxPrice = 1000;

/**
 * Компонент фильтров продаж организации
 */
const OrganizationSaleFilters: FC<OrganizationSaleFiltersProps> = ({ onSubmit }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const organizationId = getOrganizationId();

  const [filterInfo, setFilterInfo] =
    useState<SaleAndTransactionFilterInfo>(filterInfoInitialValue);

  const formik = useFormik({
    validationSchema: organizationSaleFiltersValidationScheme,
    validateOnChange: false,
    initialValues: initialValuesOrganizationSaleFilters,
    onSubmit: handleSubmit,
  });

  const {
    modelList,
    outletList,
    machineList,
    brandList,
    tasteList,
    cellCategoryList,
    cellViewList,
    cellPurposeList,
    productLineList,
    productList,
    checkedVendistaToken,
  } = useOrganizationSaleFilters(
    organizationId,
    formik.values.brandIds,
    formik.values.productLineIds,
  );

  const newOutletList = outletList?.outlets;

  const isVendistaTokenWorking =
    checkedVendistaToken?.statusToken !== VendistaTokenStatus.NOT_PROVIDED;

  const isSliderDisabled =
    formik.values.usageType === UsagePromoCodeEnum.NULL ||
    formik.values.usageType === UsagePromoCodeEnum.NO ||
    formik.values.discountType === DiscountTypePromoCodeFilterEnum.NULL ||
    formik.values.discountType === DiscountTypePromoCodeFilterEnum.FREE;

  const isDiscountGroupChoiceDisabled =
    formik.values.usageType === UsagePromoCodeEnum.NULL ||
    formik.values.usageType === UsagePromoCodeEnum.NO;

  const maxValue =
    formik.values.discountType === DiscountTypePromoCodeFilterEnum.FIXED
      ? maxDiscountFixed
      : maxDiscountPercent;

  // Вспомогательные методы
  const getSelectedItems = <T extends { id: number }>(
    items: T[] | null,
    selectedIds: number[] | null,
  ): T[] => {
    if (!items || !selectedIds) return [];
    return items.filter((item) => selectedIds.includes(item.id));
  };

  // Обработчики
  function handleSubmit(data: SalesAndTransactionsFilter) {
    onSubmit(data, filterInfo);
    navigate('/sales/organization');
  }

  const handleReset = () => {
    formik.resetForm();
  };

  const handleClose = () => {
    navigate('/sales/organization');
  };

  const handleMultiSelectChange = <T extends { id: number; name: string | null }>(
    setFieldValue: (field: string, value: number[]) => void,
    fieldName: keyof SalesAndTransactionsFilter,
  ) => {
    return ({ value }: { value: T[] | null }) => {
      const selectedIds = value?.map((item) => item.id) || [];
      setFieldValue(fieldName, selectedIds);
      const selectedNames = value?.map((item) => item.name) || [];
      setFilterInfo((prevState) => ({ ...prevState, [fieldName]: selectedNames }));
    };
  };

  const handleUsageTypeChange = (value: UsagePromoCodeEnum) => {
    formik.setFieldValue('usageType', value);
    if (value === UsagePromoCodeEnum.NULL || value === UsagePromoCodeEnum.NO) {
      formik.setFieldValue('discountType', DiscountTypePromoCodeFilterEnum.NULL);
      formik.setFieldValue('discountAmountMin', null);
      formik.setFieldValue('discountAmountMax', null);

      setFilterInfo((prevState) => ({
        ...prevState,
        discountType: DiscountTypePromoCodeFilterEnum.NULL,
        discountAmountMin: null,
        discountAmountMax: null,
      }));
    }
  };

  const handleDiscountTypeChange = (value: DiscountTypePromoCodeFilterEnum) => {
    formik.setFieldValue('discountType', value);

    const [min, max] =
      value === DiscountTypePromoCodeFilterEnum.NULL
        ? [null, null]
        : value === DiscountTypePromoCodeFilterEnum.PERCENT
          ? [minValue, maxDiscountPercent]
          : value === DiscountTypePromoCodeFilterEnum.FIXED
            ? [minValue, maxDiscountFixed]
            : [null, null];

    formik.setFieldValue('discountAmountMin', min);
    formik.setFieldValue('discountAmountMax', max);

    setFilterInfo((prevState) => ({
      ...prevState,
      discountAmountMin: min,
      discountAmountMax: max,
    }));
  };

  const handleDiscountTextFieldChange = (field: 'discountAmountMin' | 'discountAmountMax') => {
    return ({ value }: { value: string | null }) => {
      const max =
        formik.values.discountType === DiscountTypePromoCodeFilterEnum.FIXED
          ? maxDiscountFixed
          : maxDiscountPercent;
      const limitedValue = getLimitedInputNumberOrNull(value, minValue, max);

      formik.setFieldValue(field, limitedValue);

      setFilterInfo((prevState) => ({ ...prevState, [field]: limitedValue }));
    };
  };

  const handleDateChange = (value: DatePickerPropValue<'date-time-range'>) => {
    if (value) {
      const [startDate, endDate] = value;
      formik.setFieldValue('dateStartSale', startDate || null);
      formik.setFieldValue('dateEndSale', endDate || null);

      setFilterInfo((prevState) => ({
        ...prevState,
        dateStartSale: startDate || null,
        dateEndSale: endDate || null,
      }));
    }
  };

  const handleSaleMethodChange = (value: SaleMethod[] | null) => {
    formik.setFieldValue('saleMethod', value || []);
    setFilterInfo((prevState) => ({
      ...prevState,
      saleMethod: value,
    }));
  };

  const handleTransactionStatusChange = (value: TransactionStatus | null) => {
    formik.setFieldValue('transactionStatus', value);
    setFilterInfo((prevState) => ({
      ...prevState,
      transactionStatus: value,
    }));
  };

  const handleAmountDiscountSliderChange = (value: [number, number]) => {
    if (value) {
      formik.setFieldValue('discountAmountMin', value[0]);
      formik.setFieldValue('discountAmountMax', value[1]);
      setFilterInfo((prevState) => ({
        ...prevState,
        discountAmountMin: value[0],
        discountAmountMax: value[1],
      }));
    }
  };

  const handlePriceSliderChange = (value: [number, number]) => {
    if (value) {
      formik.setFieldValue('priceAmountMin', value[0]);
      formik.setFieldValue('priceAmountMax', value[1]);
      setFilterInfo((prevState) => ({
        ...prevState,
        priceAmountMin: value[0],
        priceAmountMax: value[1],
      }));
    }
  };

  const handlePriceTextFieldChange = (field: 'priceAmountMin' | 'priceAmountMax') => {
    return ({ value }: { value: string | null }) => {
      const limitedValue = getLimitedInputNumberOrNull(value, minValue, maxPrice);

      formik.setFieldValue(field, limitedValue);

      setFilterInfo((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    };
  };

  // render методы
  const renderModelAndOutlet = () => (
    <ContentCard className={styles.contentCard}>
      <Combobox
        className={styles.comboBox}
        label={t('organization.sales.filters.model.combobox.label')}
        multiple
        status={formik.errors.machineModelIds ? 'alert' : undefined}
        items={modelList || []}
        getItemLabel={(item) => item.name}
        getItemKey={(item) => item.id}
        value={getSelectedItems(modelList, formik.values.machineModelIds)}
        onChange={handleMultiSelectChange(formik.setFieldValue, 'machineModelIds')}
        placeholder={t('organization.sales.filters.model.combobox.placeholder')}
      />
      <Combobox
        className={styles.comboBox}
        label={t('organization.sales.filters.outlet.combobox.label')}
        multiple
        status={formik.errors.outletIds ? 'alert' : undefined}
        items={newOutletList || []}
        getItemLabel={(item) => item.name || ''}
        getItemKey={(item) => item.id}
        value={getSelectedItems(newOutletList || [], formik.values.outletIds)}
        onChange={handleMultiSelectChange(formik.setFieldValue, 'outletIds')}
        placeholder={t('organization.sales.filters.outlet.combobox.placeholder')}
      />
      <Combobox
        className={styles.comboBox}
        label={t('organization.sales.filters.machines.combobox.label')}
        multiple
        status={formik.errors.machineIds ? 'alert' : undefined}
        items={machineList || []}
        getItemLabel={(item) => item.name || ''}
        getItemKey={(item) => item.id}
        value={getSelectedItems(machineList, formik.values.machineIds)}
        onChange={handleMultiSelectChange(formik.setFieldValue, 'machineIds')}
        placeholder={t('organization.sales.filters.machines.combobox.placeholder')}
      />
    </ContentCard>
  );

  const renderBrandAndProductLine = () => (
    <ContentCard className={styles.contentCard}>
      <Combobox
        className={styles.comboBox}
        label={t('organization.sales.filters.brand.combobox.label')}
        multiple
        status={formik.errors.brandIds ? 'alert' : undefined}
        items={brandList || []}
        getItemLabel={(item: BrandDTO) => item.name || ''}
        getItemKey={(item: BrandDTO) => item.id}
        value={getSelectedItems(brandList, formik.values.brandIds)}
        onChange={handleMultiSelectChange(formik.setFieldValue, 'brandIds')}
        placeholder={t('organization.sales.filters.brand.combobox.placeholder')}
      />
      <Combobox
        className={styles.comboBox}
        label={t('organization.sales.filters.productLine.combobox.label')}
        multiple
        disabled={!!(formik.values.brandIds && formik.values.brandIds.length === 0)}
        groups={brandList}
        status={formik.errors.productLineIds ? 'alert' : undefined}
        items={productLineList || []}
        getItemLabel={(item) => item.name || ''}
        getItemKey={(item) => item.id}
        getGroupLabel={({ name }) => name}
        getGroupKey={({ id }) => id}
        getItemGroupKey={({ brandId }) => brandId}
        value={getSelectedItems(productLineList, formik.values.productLineIds)}
        onChange={handleMultiSelectChange(formik.setFieldValue, 'productLineIds')}
        placeholder={t('organization.sales.filters.productLine.combobox.placeholder')}
      />
      <Combobox
        className={styles.comboBox}
        label={t('organization.sales.filters.product.combobox.label')}
        multiple
        disabled={!!(formik.values.productLineIds && formik.values.productLineIds.length === 0)}
        groups={productLineList}
        status={formik.errors.productIds ? 'alert' : undefined}
        items={productList || []}
        getItemLabel={(item) => item.name || ''}
        getItemKey={(item) => item.id}
        getGroupLabel={({ name }) => name}
        getGroupKey={({ id }) => id}
        getItemGroupKey={({ ingredientLineId }) => ingredientLineId}
        value={getSelectedItems(productList || [], formik.values.productIds)}
        onChange={handleMultiSelectChange(formik.setFieldValue, 'productIds')}
        placeholder={t('organization.sales.filters.product.combobox.placeholder')}
      />
    </ContentCard>
  );

  const renderCategoryAndTaste = () => (
    <ContentCard className={styles.contentCard}>
      <Combobox
        label={t('organization.sales.filters.category.combobox.label')}
        multiple
        status={formik.errors.cellCategoryIds ? 'alert' : undefined}
        items={cellCategoryList || []}
        getItemLabel={(item) => item.name || ''}
        getItemKey={(item) => item.id}
        value={getSelectedItems(cellCategoryList || [], formik.values.cellCategoryIds)}
        onChange={handleMultiSelectChange(formik.setFieldValue, 'cellCategoryIds')}
        placeholder={t('organization.sales.filters.category.combobox.placeholder')}
      />
      <Combobox
        label={t('organization.sales.filters.purpose.combobox.label')}
        multiple
        status={formik.errors.cellPurposeIds ? 'alert' : undefined}
        items={cellPurposeList || []}
        getItemLabel={(item) => item.name || ''}
        getItemKey={(item) => item.id}
        value={getSelectedItems(cellPurposeList || [], formik.values.cellPurposeIds)}
        onChange={handleMultiSelectChange(formik.setFieldValue, 'cellPurposeIds')}
        placeholder={t('organization.sales.filters.purpose.combobox.placeholder')}
      />
      <Combobox
        label={t('organization.sales.filters.taste.combobox.label')}
        multiple
        status={formik.errors.tasteIds ? 'alert' : undefined}
        items={tasteList || []}
        getItemLabel={(item) => item.name || ''}
        getItemKey={(item) => item.id}
        value={getSelectedItems(tasteList || [], formik.values.tasteIds)}
        onChange={handleMultiSelectChange(formik.setFieldValue, 'tasteIds')}
        placeholder={t('organization.sales.filters.taste.combobox.placeholder')}
      />
      <Combobox
        label={t('organization.sales.filters.view.combobox.label')}
        multiple
        status={formik.errors.viewIds ? 'alert' : undefined}
        items={cellViewList || []}
        getItemLabel={(item) => item.name || ''}
        getItemKey={(item) => item.id}
        value={getSelectedItems(cellViewList || [], formik.values.viewIds)}
        onChange={handleMultiSelectChange(formik.setFieldValue, 'viewIds')}
        placeholder={t('organization.sales.filters.view.combobox.placeholder')}
      />
    </ContentCard>
  );

  const renderDiscountPercentAmountSlider = () => (
    <Slider
      key="DiscountPercentAmountSlider"
      size="l"
      range
      disabled={isSliderDisabled}
      className={styles.slider}
      value={[
        formik.values.discountAmountMin || minValue,
        formik.values.discountAmountMax || maxValue,
      ]}
      min={minValue}
      max={maxValue}
      step={1}
      withTooltip
      onChange={({ value }) => handleAmountDiscountSliderChange(value)}
    />
  );

  const renderPromoCode = () => {
    return (
      <ContentCard className={styles.verticalContentCard}>
        <VerticalContainer space="s" align="start">
          <Text view="secondary" size="m">
            {t('organization.sales.filters.promoCode.usageType.label')}
          </Text>
          <ChoiceGroup
            size="l"
            value={formik.values.usageType}
            items={Object.values(UsagePromoCodeEnum)}
            name=""
            getItemLabel={(item: UsagePromoCodeEnum) =>
              t(`organization.sales.filters.promoCode.usageType.${item}`)
            }
            onChange={({ value }) => handleUsageTypeChange(value)}
          />
        </VerticalContainer>
        <VerticalContainer space="s" align="start">
          <Text view="secondary" size="m">
            {t('organization.sales.filters.promoCode.amountType.label')}
          </Text>
          <ChoiceGroup
            size="l"
            disabled={isDiscountGroupChoiceDisabled}
            value={formik.values.discountType}
            items={Object.values(DiscountTypePromoCodeFilterEnum)}
            name="discountType"
            getItemLabel={(item: DiscountTypePromoCodeFilterEnum) =>
              t(`organization.sales.filters.promoCode.amountType.${item}`)
            }
            onChange={({ value }) => handleDiscountTypeChange(value)}
          />
        </VerticalContainer>
        <VerticalContainer space="s">
          <Text view="secondary" size="m">
            {t('organization.sales.filters.promoCode.discountAmount.label')}
          </Text>
          {renderDiscountPercentAmountSlider()}
          <HorizontalContainer space="s">
            <TextField
              size="m"
              leftSide={t(
                'organization.sales.filters.promoCode.discountAmount.from.textField.leftSide',
              )}
              disabled={isSliderDisabled}
              className={styles.textField}
              value={
                formik.values.discountAmountMin !== null
                  ? String(formik.values.discountAmountMin)
                  : ''
              }
              onChange={handleDiscountTextFieldChange('discountAmountMin')}
            />
            <TextField
              size="m"
              leftSide={t(
                'organization.sales.filters.promoCode.discountAmount.to.textField.leftSide',
              )}
              disabled={isSliderDisabled}
              className={styles.textField}
              value={
                formik.values.discountAmountMax !== null
                  ? String(formik.values.discountAmountMax)
                  : ''
              }
              onChange={handleDiscountTextFieldChange('discountAmountMax')}
            />
          </HorizontalContainer>
        </VerticalContainer>
      </ContentCard>
    );
  };

  const renderSaleInfo = () => (
    <ContentCard className={styles.verticalContentCard}>
      <Combobox
        label={t('organization.sales.filters.salesMethod.combobox.label')}
        items={Object.values(SaleMethod)}
        multiple
        value={formik.values.saleMethod || []}
        onChange={({ value }) => handleSaleMethodChange(value)}
        getItemLabel={(item: SaleMethod) => t(`organization.sales.filters.salesMethod.${item}`)}
        getItemKey={(item: SaleMethod) => item}
        placeholder={t('organization.sales.filters.salesMethod.combobox.placeholder')}
      />
      <VerticalContainer space="s">
        <Text view="secondary" size="m">
          {t('organization.sales.filters.price.label')}
        </Text>
        <Slider
          key="PriceSlider"
          size="l"
          range
          className={styles.slider}
          value={[
            formik.values.priceAmountMin || minValue,
            formik.values.priceAmountMax || maxPrice,
          ]}
          min={minValue}
          max={maxPrice}
          step={1}
          withTooltip
          onChange={({ value }) => handlePriceSliderChange(value)}
        />
        <HorizontalContainer space="s">
          <TextField
            size="m"
            leftSide={t('organization.sales.filters.price.from.textField.leftSide')}
            className={styles.textField}
            value={
              formik.values.priceAmountMin !== null ? String(formik.values.priceAmountMin) : ''
            }
            onChange={handlePriceTextFieldChange('priceAmountMin')}
          />
          <TextField
            size="m"
            leftSide={t('organization.sales.filters.price.to.textField.leftSide')}
            className={styles.textField}
            value={
              formik.values.priceAmountMax !== null ? String(formik.values.priceAmountMax) : ''
            }
            onChange={handlePriceTextFieldChange('priceAmountMax')}
          />
        </HorizontalContainer>
      </VerticalContainer>
      {isVendistaTokenWorking && (
        <Combobox
          label={t('organization.sales.filters.transactionStatus.combobox.label')}
          items={Object.values(TransactionStatus)}
          value={formik.values.transactionStatus}
          onChange={({ value }) => handleTransactionStatusChange(value)}
          getItemLabel={(item: TransactionStatus) =>
            t(`organization.sales.filters.transactionStatus.${item}`)
          }
          getItemKey={(item: TransactionStatus) => item}
          placeholder={t('organization.sales.filters.transactionStatus.combobox.placeholder')}
        />
      )}
    </ContentCard>
  );

  const renderDatePicker = () => (
    <ContentCard className={styles.verticalContentCard}>
      <DatePicker
        label={t('organization.sales.filters.salesPeriod.combobox.label')}
        type="date-time-range"
        format="dd/MM/yyyy HH:mm"
        separator="/"
        placeholder={t('organization.sales.filters.salesPeriod.combobox.placeholder')}
        className={styles.datePicker}
        value={[formik.values.dateStartSale || undefined, formik.values.dateEndSale || undefined]}
        onChange={({ value }) => handleDateChange(value)}
        multiplicitySeconds={0}
        rightSide={[IconCalendar as any, IconCalendar as any]}
      />
    </ContentCard>
  );

  const renderActions = () => (
    <HorizontalContainer space="m">
      <Button
        view="ghost"
        size="m"
        label={t('organization.sales.filters.clear.button.label')}
        onClick={handleReset}
      />
      <Button
        view="primary"
        size="m"
        label={t('organization.sales.filters.applyFilters.button.label')}
        onClick={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      />
    </HorizontalContainer>
  );

  return (
    <DefaultFullScreenFilters
      modalTitle={t('organization.sales.filters.modal.title')}
      renderActions={renderActions}
      onClose={handleClose}
      className={styles.OrganizationSaleFilters}
    >
      <VerticalContainer space="s">
        {renderModelAndOutlet()}
        {renderBrandAndProductLine()}
        {renderCategoryAndTaste()}
        <HorizontalContainer space="s">
          {renderPromoCode()}
          {renderSaleInfo()}
          {renderDatePicker()}
        </HorizontalContainer>
      </VerticalContainer>
    </DefaultFullScreenFilters>
  );
};

export default OrganizationSaleFilters;
