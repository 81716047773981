import { FC, useState } from 'react';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import styles from './LeftMenuItemGroup.module.scss';
import classNames from 'classnames';
import LeftMenuItem from './LeftMenuItem';
import { defaultIconProps } from '../../../consts/defaultIconProps';
import HorizontalContainer from '../../HorizontalContainer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconArrowUp } from '../../../assets/icon/iconArrowUp';
import { IconArrowDown } from '../../../assets/icon/iconArrowDown';
import { LeftMenuItemGroupProps } from './types';

/**
 * Группа элементов в выпадающем меню
 */
const LeftMenuItemGroup: FC<LeftMenuItemGroupProps> = ({ group, onClose }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isSingleGroup = group.listItems.length === 1;
  const isViewGroup = group.listItems.length > 0;
  const isSelectedGroup = group.listItems?.find((item) => item.path === pathname);
  const [isOpen, setIsOpen] = useState(false);

  // Обработчики
  const handleClick = (path: string) => {
    navigate(path);
    onClose && onClose();
  };

  if (!isViewGroup) return null;

  return (
    <div className={classNames(styles.group, (isOpen || isSelectedGroup) && styles.select)}>
      <div
        className={classNames(
          styles.LeftMenuItemGroupCard,
          (isOpen || isSelectedGroup) && styles.select,
          isSingleGroup && styles.single,
        )}
        onClick={() =>
          !isSingleGroup
            ? setIsOpen((prevState) => !prevState)
            : handleClick(group?.listItems[0]?.path)
        }
      >
        <HorizontalContainer space="xs" align="center">
          {group.renderIcon()}
          <Text view="secondary" weight="medium" size="s">
            {t(
              isSingleGroup
                ? group.listItems[0].fullLabel || group.listItems[0].label
                : group.label,
            )}
          </Text>
        </HorizontalContainer>
        {!isSingleGroup &&
          (isOpen || isSelectedGroup ? (
            <IconArrowUp size="s" {...defaultIconProps} />
          ) : (
            <IconArrowDown size="s" {...defaultIconProps} />
          ))}
      </div>
      {!isSingleGroup && (isOpen || isSelectedGroup) && (
        <div className={styles.line}>
          {group.listItems.map((item) => (
            <LeftMenuItem item={item} key={item.label} onClose={onClose} />
          ))}
        </div>
      )}
    </div>
  );
};

export default LeftMenuItemGroup;
