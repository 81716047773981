import { FC, useMemo } from 'react';
import { MachineStorageDefaultSchemeProps } from './types';
import styles from './MachineStorageDefaultScheme.module.scss';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { ProductCellsByList } from '../../../../../types/serverInterface/machineListDTO';
import { ProductGroup } from '../../../../../types/serverInterface/storageDTO';
import { MachineCellGroup } from '../../../../../types/serverInterface/machineDTO';
import MachineStorageDefaultCellItem from './MachineStorageDefaultCellItem';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import { getSortedProductGroup } from '../../../../../components/Machine/MachineStorage/helpers';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { IconFaucet } from '../../../../../assets/icon/iconFaucet';
import { IconBottle } from '../../../../../assets/icon/iconBottle';
import { defaultIconProps } from '../../../../../consts/defaultIconProps';
import { IconShakerCup } from '../../../../../assets/icon/iconShakerCup';

/**
 * Дефолтное значение ячеек склада автомата
 */
const initialStorage: {
  [key in ProductGroup]: ProductCellsByList[];
} = {
  [ProductGroup.CONCENTRATE]: [],
  [ProductGroup.POWDER]: [],
  [ProductGroup.COFFEE]: [],
};

/**
 * Дефолтная схема в схеме остатков автомата
 */
const MachineStorageDefaultScheme: FC<MachineStorageDefaultSchemeProps> = ({
  cells,
  cellWaters,
  cellCups,
}) => {
  const sortedCells = useMemo(() => {
    const separateStorageInfoByGroup = (storageInfoArray: ProductCellsByList[] | null) => {
      const sortedCells = [...(storageInfoArray || [])];
      sortedCells.sort((a, b) => a.cellNumber - b.cellNumber);

      const separatedStorageInfo: {
        [key in ProductGroup]: ProductCellsByList[];
      } = JSON.parse(JSON.stringify(initialStorage));

      sortedCells.forEach((storageInfo) => {
        separatedStorageInfo[storageInfo.group].push(storageInfo);
      });

      return separatedStorageInfo;
    };

    return separateStorageInfoByGroup(cells);
  }, [cells]);

  const sortedGroup = useMemo(() => getSortedProductGroup(sortedCells), [sortedCells]);

  //render методы
  const renderSchemeDisposables = () => (
    <HorizontalContainer space="s">
      <HorizontalContainer space="2xs">
        {cellWaters?.map(
          (cell, index) =>
            cell.isCount && (
              <MachineStorageDefaultCellItem
                key={index}
                cell={{ ...cell, group: MachineCellGroup.WATER }}
              />
            ),
        )}
        {cellCups?.map(
          (cell, index) =>
            cell.isCount && (
              <MachineStorageDefaultCellItem
                key={index}
                cell={{ ...cell, group: MachineCellGroup.CUP }}
              />
            ),
        )}
      </HorizontalContainer>
    </HorizontalContainer>
  );

  const renderNumberDisposables = () => (
    <VerticalContainer space={0} align="start" justify="center">
      <HorizontalContainer space="2xs">
        {cellWaters?.map(
          (cell, index) =>
            cell.isActive && (
              <HorizontalContainer className={styles.waterCell} space={0} key={index}>
                {cell.isCount ? (
                  <IconBottle {...defaultIconProps} size="s" />
                ) : (
                  <IconFaucet {...defaultIconProps} size="s" />
                )}
                <Text weight="semibold" size="m" className={styles.text}>
                  {/* Для того чтобы при дробных значениях была запятая вместо точки */}
                  {cell.isCount ? (cell.volume || 0).toLocaleString('ru-RU') : '∞'}
                </Text>
              </HorizontalContainer>
            ),
        )}
      </HorizontalContainer>
      <HorizontalContainer space="2xs">
        {cellCups?.map((cell, index) => (
          <HorizontalContainer className={styles.cupCell} space={0} key={index}>
            <IconShakerCup {...defaultIconProps} size="s" />
            <Text weight="semibold" size="m" className={styles.text}>
              {cell.isCount ? cell.volume || 0 : '∞'}
            </Text>
          </HorizontalContainer>
        ))}
      </HorizontalContainer>
    </VerticalContainer>
  );

  const renderDisposables = () => (
    <HorizontalContainer space="s">
      {renderSchemeDisposables()}
      {renderNumberDisposables()}
    </HorizontalContainer>
  );

  const renderProductScheme = () => (
    <HorizontalContainer space="s">
      {sortedGroup.map((key) => (
        <HorizontalContainer space="2xs" key={key}>
          {sortedCells[key].map((cell, index) => (
            <MachineStorageDefaultCellItem
              key={index}
              cell={{
                ...cell,
                volume: cell.ingredientCell?.ingredientId == null ? 0 : cell.volume || 0,
              }}
            />
          ))}
        </HorizontalContainer>
      ))}
    </HorizontalContainer>
  );

  return (
    <>
      {renderDisposables()}
      {renderProductScheme()}
    </>
  );
};

export default MachineStorageDefaultScheme;
