import { FC } from 'react';
import classNames from 'classnames';
import { MachineStorageSnackSchemeItemProps } from './types';
import { getColorByVolume } from '../../../../../pages/MachineControlPage/MachineList/MachineStorageListCell/MachineStorageSnackMatrix/MachineStorageSnackMatrixItem/helpers';
import styles from './MachineStorageSnackSchemeItem.module.scss';
import { Text } from '@consta/uikit/__internal__/src/components/Text';

/**
 * Ячейка на схеме наполнения главной страницы снек автомата
 */
const MachineStorageSnackSchemeItem: FC<MachineStorageSnackSchemeItemProps> = ({
  snackSchemeItem,
}) => {
  const { minVolume, warningVolume, volume, motorIds, cellNumber } = snackSchemeItem;

  return (
    <div
      className={classNames(styles.gridCell, getColorByVolume(minVolume, warningVolume, volume))}
      style={{
        gridColumn: `span ${motorIds.length}`,
      }}
    >
      <Text weight="medium">{cellNumber}</Text>
    </div>
  );
};

export default MachineStorageSnackSchemeItem;
