import { SVGProps } from 'react';
import { createIcon } from '@consta/uikit/__internal__/src/icons/createIcon';

const IconCloseSizeXS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" {...props}>
    <path d="M9.76518 2.76517C9.91162 2.61872 9.91162 2.38128 9.76518 2.23483C9.61873 2.08839 9.38129 2.08839 9.23485 2.23483L6.00001 5.46968L2.76517 2.23484C2.61872 2.08839 2.38128 2.08839 2.23483 2.23484C2.08839 2.38128 2.08839 2.61872 2.23483 2.76517L5.46968 6.00001L2.23485 9.23484C2.0884 9.38128 2.0884 9.61872 2.23485 9.76517C2.3813 9.91161 2.61873 9.91161 2.76518 9.76517L6.00001 6.53034L9.23484 9.76517C9.38128 9.91161 9.61872 9.91161 9.76516 9.76517C9.91161 9.61872 9.91161 9.38128 9.76516 9.23484L6.53034 6.00001L9.76518 2.76517Z" />
  </svg>
);

const IconCloseSizeS = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path d="M13.0203 3.68681C13.2155 3.49154 13.2155 3.17496 13.0203 2.9797C12.825 2.78444 12.5084 2.78444 12.3132 2.9797L8.00005 7.29282L3.68693 2.9797C3.49167 2.78444 3.17508 2.78444 2.97982 2.9797C2.78456 3.17496 2.78456 3.49155 2.97982 3.68681L7.29294 7.99993L2.97984 12.313C2.78458 12.5083 2.78458 12.8249 2.97984 13.0201C3.1751 13.2154 3.49168 13.2154 3.68695 13.0201L8.00005 8.70704L12.3132 13.0201C12.5084 13.2154 12.825 13.2154 13.0203 13.0201C13.2155 12.8249 13.2155 12.5083 13.0203 12.313L8.70716 7.99993L13.0203 3.68681Z" />
  </svg>
);

const IconCloseSizeM = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path d="M19.5304 5.53033C19.8232 5.23744 19.8232 4.76256 19.5304 4.46967C19.2375 4.17678 18.7626 4.17678 18.4697 4.46967L12 10.9394L5.53033 4.46967C5.23744 4.17678 4.76256 4.17678 4.46967 4.46967C4.17678 4.76257 4.17678 5.23744 4.46967 5.53033L10.9394 12L4.4697 18.4697C4.1768 18.7626 4.1768 19.2374 4.4697 19.5303C4.76259 19.8232 5.23746 19.8232 5.53036 19.5303L12 13.0607L18.4697 19.5303C18.7626 19.8232 19.2374 19.8232 19.5303 19.5303C19.8232 19.2374 19.8232 18.7626 19.5303 18.4697L13.0607 12L19.5304 5.53033Z" />
  </svg>
);

export const IconClose = createIcon({
  name: 'IconClose',
  xs: IconCloseSizeXS,
  s: IconCloseSizeS,
  m: IconCloseSizeM,
});
