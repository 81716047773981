/**
 * dto авторизации vendista
 */
export type SignInVendistaDTO = {
  /**
   * login пользователя vendista
   */
  login: string;
  /**
   * Пароль пользователя vendista
   */
  password: string;
  /**
   * id организации в телеметрии
   */
  organizationId: number;
};

/**
 * Статусы работы интеграции vendista
 */
export enum VendistaTokenStatus {
  /**
   * Вообще не заводилась
   */
  NOT_PROVIDED = 'NOT_PROVIDED',
  /**
   * Заводилась, но не проверена
   */
  UNVERIFIED = 'UNVERIFIED',
  /**
   * Работает, все ок
   */
  WORKING = 'WORKING',
  /**
   * Заводилась, но не работает
   */
  NOT_WORKING = 'NOT_WORKING',
}

/**
 * Проверка наличия и статуса работы интеграции vendista
 */
export type CheckVendistaTokenDTO = {
  /**
   * Статус работы интеграции vendista
   */
  statusToken: VendistaTokenStatus;
};

/**
 * dto информации о подключении автомата к интеграции vendista
 */
export type MachineVendistaSettingDTO = {
  /**
   * id терминала
   */
  termId: number;
};

/**
 * Элемент списка терминалов организации
 */
export type MachineVendistaSettingListItem = {
  /**
   * id автомата
   */
  machineId: number;
  /**
   * Номер терминала
   */
  vendistaTerminalId: number;
};

/**
 * dto изменения настройки подключения автомата к интеграции vendista
 */
export type EditMachineVendistaSettingDTO = {
  /**
   * id терминала
   */
  vendistaTerminalId: number | null;
  /**
   * Серийный номер автомата
   */
  machineSerialNumber: string;
  /**
   * Флаг снек автомата
   */
  isSnackMachine: boolean;
};

/**
 * Статус транзакции
 */
export enum TransactionStatus {
  /**
   * Отменена
   */
  CANCELED = 'CANCELED',
  /**
   * Не отменена
   */
  NOT_CANCELED = 'NOT_CANCELED',
}
