import { FC, useState } from 'react';
import DefaultModal from '../../../../../components/DefaultModal';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import AlertButton from '../../../../../components/AlertButton';
import styles from './VendistaSignOutForm.module.scss';
import { VendistaSignOutFormProps } from './types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../app/hooks/store';
import {
  checkVendistaTokenAction,
  deleteVendistaSettingAction,
} from '../../../../../state/vendista/action';

/**
 * Форма выхода из Vendista
 */
const VendistaSignOutForm: FC<VendistaSignOutFormProps> = ({ organizationId, isOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  // Обработчики
  const handleSignOutClick = () => {
    setIsLoading(true);
    dispatch(deleteVendistaSettingAction(organizationId))
      .then(() => {
        dispatch(checkVendistaTokenAction(organizationId)).finally(() => {
          onClose();
          setIsLoading(false);
        });
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  // render методы
  const renderActions = () => (
    <>
      <Button
        size="l"
        view="clear"
        label={t('integration.vendista.signOut.cancel.button.label')}
        loading={isLoading}
        onClick={onClose}
      />
      <AlertButton
        size="l"
        label={t('integration.vendista.signOut.turnOff.button.label')}
        loading={isLoading}
        onClick={handleSignOutClick}
      />
    </>
  );

  return (
    <DefaultModal
      modalTitle={t('integration.vendista.signOut.modal.title')}
      isOpen={isOpen}
      onClose={onClose}
      renderActions={renderActions}
      className={styles.VendistaSignOutForm}
    >
      <Text size="l">{t('integration.vendista.signOut.information.label')}</Text>
    </DefaultModal>
  );
};

export default VendistaSignOutForm;
