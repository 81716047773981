import { FC, useEffect, useMemo } from 'react';
import { MachineSmallListItemDTO } from '../../../../../types/serverInterface/machineDTO';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import { Combobox } from '@consta/uikit/Combobox';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconTrash } from '../../../../../assets/icon/iconTrash';
import { IconAdd } from '../../../../../assets/icon/iconAdd';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { EditOutletDTO, OutletListItem } from '../../../../../types/serverInterface/outletDTO';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks/store';
import {
  selectMachineSerialNumberList,
  selectSmallMachineList,
} from '../../../../../state/machineControl/selectors';
import {
  getMachineListByOrganizationIdAction,
  getMachineSerialNumberListByOrganizationIdAction,
} from '../../../../../state/machineControl/actions';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компонента EditOutletMachines
 */
type EditOutletMachinesProps = {
  /**
   * id организации
   */
  organizationId: number;
  /**
   * Торговая точка
   */
  outlet: EditOutletDTO;
  /**
   * Список торговых точек
   */
  outletList: OutletListItem[];
  /**
   * Обработчик изменения торговой точки
   *
   * @param key ключ поля
   */
  onChange: (key: keyof EditOutletDTO) => ({ value }: { value: string | number[] | null }) => void;
};

/**
 * Форма изменения автоматов торговой точки
 */
const EditOutletMachines: FC<EditOutletMachinesProps> = ({
  outlet,
  organizationId,
  outletList,
  onChange,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { state: machinesList } = useAppSelector(selectMachineSerialNumberList());
  const { machineIds } = outlet;

  const machineMap = useMemo(() => {
    const map: Record<number, MachineSmallListItemDTO> = {};

    machinesList?.forEach((machine) => {
      map[machine.id] = map[machine.id] || [];
      map[machine.id] = machine;
    });

    return map;
  }, [machinesList]);

  const selectedList = useMemo(() => {
    return machineIds.map((id) => machineMap[id]);
  }, [JSON.stringify(machineIds), JSON.stringify(machineMap)]);

  const filterMachineList = useMemo(() => {
    return machinesList?.filter(({ id }) => !machineIds.includes(id));
  }, [JSON.stringify(machinesList), JSON.stringify(machineIds)]);

  useEffect(() => {
    dispatch(getMachineSerialNumberListByOrganizationIdAction(organizationId));
  }, [dispatch, organizationId]);

  // Обработчики
  const handleAddClick = () => {
    onChange('machineIds')({ value: [...machineIds, 0] });
  };

  const handleEditMachineChange = ({
    index,
    machineId,
  }: {
    index: number;
    machineId: number | null;
  }) => {
    const updatedList = [...machineIds];

    updatedList[index] = machineId || 0;

    onChange('machineIds')({ value: updatedList });
  };

  const handleDelete = (index: number) => () => {
    const updatedList = [...machineIds];

    updatedList.splice(index, 1);

    onChange('machineIds')({ value: updatedList });
  };

  return (
    <VerticalContainer isAutoWidth>
      {machinesList &&
        selectedList.map((item, index) => (
          <HorizontalContainer key={index} isAutoSpace>
            <Combobox
              items={item ? [item, ...(filterMachineList || [])] : filterMachineList || []}
              value={item}
              getItemLabel={(machine) => machine?.name || ''}
              getItemKey={(machine) => machine?.id || index}
              getItemGroupKey={(machine) => machine.outletId || 0}
              groups={[{ id: 0, name: 'Без торговой точки' } as OutletListItem, ...outletList]}
              getGroupKey={(outlet: OutletListItem) => outlet.id}
              getGroupLabel={(outlet: OutletListItem) => outlet.name || ''}
              onChange={({ value }) => {
                handleEditMachineChange({ machineId: value && value.id, index });
              }}
            />
            <Button
              onlyIcon
              iconLeft={IconTrash as any}
              view="ghost"
              onClick={handleDelete(index)}
            />
          </HorizontalContainer>
        ))}
      <Button
        label={t('outlet.edit.machines.add.button.label')}
        iconLeft={IconAdd as any}
        view="ghost"
        onClick={handleAddClick}
      />
    </VerticalContainer>
  );
};

export default EditOutletMachines;
