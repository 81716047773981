import { MachineSnackCell } from '../../types/serverInterface/machineListDTO';
import {
  MachineHomeSnackCell,
  MachineStorageInfoSnackCell,
} from '../../types/serverInterface/machineDTO';

export type BackSnackCell = MachineSnackCell & MachineHomeSnackCell & MachineStorageInfoSnackCell;

export const snackCells: BackSnackCell[] = [
  // 1 row
  {
    snackBrandName: 'Nestle',
    snackProductName: 'Вода детская Святой Источник Спортик 0,33л Спорт негаз. ПЭТ',
    snackBrandId: 1,
    snackProductId: 56,
    id: 1,
    cellNumber: 101,
    volume: 1,
    isManuallyDisabled: false,
    maxVolume: 5,
    warningVolume: 3,
    minVolume: 2,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [101, 102],
    rowNumber: 1,
    motorNumber: 101,
    expirationTimer: null,
  },
  {
    snackBrandName: 'Кондитерская фабрика "Красный Октябрь"',
    snackProductName: 'Шоколад "Алёнка"',
    snackBrandId: 2,
    snackProductId: 5,
    id: 2,
    cellNumber: 102,
    volume: 4,
    isManuallyDisabled: false,
    maxVolume: 5,
    warningVolume: 3,
    minVolume: 3,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [103],
    rowNumber: 1,
    motorNumber: 103,
    expirationTimer: null,
  },
  {
    snackBrandName: 'Nestle',
    snackProductName: 'Печенье',
    snackBrandId: 1,
    snackProductId: 2,
    id: 3,
    cellNumber: 103,
    volume: 1,
    isManuallyDisabled: true,
    maxVolume: 3,
    warningVolume: 3,
    minVolume: 1,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [104, 105, 106],
    rowNumber: 1,
    motorNumber: 105,
    expirationTimer: null,
  },
  {
    snackBrandName: 'Mars',
    snackProductName: 'Шоколадный батончик "Mars"',
    snackBrandId: 3,
    snackProductId: 14,
    id: 4,
    cellNumber: 104,
    volume: 5,
    isManuallyDisabled: false,
    maxVolume: 5,
    warningVolume: 3,
    minVolume: 5,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [107],
    rowNumber: 1,
    motorNumber: 107,
    expirationTimer: null,
  },
  {
    snackBrandName: 'PepsiCo',
    snackProductName: 'Напиток "Tropicana Апельсин"',
    snackBrandId: 4,
    snackProductId: 18,
    id: 5,
    cellNumber: 105,
    volume: 2,
    isManuallyDisabled: false,
    maxVolume: 4,
    warningVolume: 3,
    minVolume: 1,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [108, 109],
    rowNumber: 1,
    motorNumber: 108,
    expirationTimer: null,
  },
  {
    snackBrandName: 'Mondelez',
    snackProductName: 'Конфеты "Milka Девочка с коровкой"',
    snackBrandId: 5,
    snackProductId: 22,
    id: 6,
    cellNumber: 106,
    volume: 0,
    isManuallyDisabled: false,
    maxVolume: 1,
    warningVolume: 3,
    minVolume: 0,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [110],
    rowNumber: 1,
    motorNumber: 110,
    expirationTimer: null,
  },

  // 2 row
  {
    snackBrandName: 'Nestle',
    snackProductName: 'Молочный шоколад',
    snackBrandId: 1,
    snackProductId: 3,
    id: 7,
    cellNumber: 107,
    volume: 3,
    isManuallyDisabled: false,
    maxVolume: 5,
    warningVolume: 3,
    minVolume: 2,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [201, 202],
    rowNumber: 2,
    motorNumber: 201,
    expirationTimer: null,
  },
  {
    snackBrandName: 'Mars',
    snackProductName: 'Шоколадный батончик "Mars"',
    snackBrandId: 3,
    snackProductId: 14,
    id: 8,
    cellNumber: 108,
    volume: 5,
    isManuallyDisabled: false,
    maxVolume: 5,
    warningVolume: 3,
    minVolume: 5,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [203],
    rowNumber: 2,
    motorNumber: 203,
    expirationTimer: null,
  },
  {
    snackBrandName: 'Кондитерская фабрика "Красный Октябрь"',
    snackProductName: 'Зефир "Фруктовый"',
    snackBrandId: 2,
    snackProductId: 9,
    id: 9,
    cellNumber: 109,
    volume: 2,
    isManuallyDisabled: true,
    maxVolume: 4,
    warningVolume: 3,
    minVolume: 1,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [204, 205],
    rowNumber: 2,
    motorNumber: 204,
    expirationTimer: null,
  },
  {
    snackBrandName: 'Doritos',
    snackProductName: 'Чипсы "Doritos Сырные"',
    snackBrandId: 20,
    snackProductId: 54,
    id: 10,
    cellNumber: 110,
    volume: 4,
    isManuallyDisabled: false,
    maxVolume: 4,
    warningVolume: 3,
    minVolume: 4,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [206],
    rowNumber: 2,
    motorNumber: 206,
    expirationTimer: null,
  },
  {
    snackBrandName: 'Pringles',
    snackProductName: 'Чипсы "Pringles Оригинальные"',
    snackBrandId: 18,
    snackProductId: 51,
    id: 11,
    cellNumber: 111,
    volume: 1,
    isManuallyDisabled: false,
    maxVolume: 3,
    warningVolume: 3,
    minVolume: 1,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [207],
    rowNumber: 2,
    motorNumber: 207,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 12,
    cellNumber: 112,
    volume: 0,
    isManuallyDisabled: false,
    maxVolume: 1,
    warningVolume: 3,
    minVolume: 0,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [208],
    rowNumber: 2,
    motorNumber: 208,
    expirationTimer: null,
  },

  // 3 row
  /*{
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 13,
    cellNumber: 113,
    volume: 3,
    isManuallyDisabled: false,
    maxVolume: 5,
    warningVolume: 3,
    minVolume: 2,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    motorIds: [301],
    rowNumber: 3,
    motorNumber: 301,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 14,
    cellNumber: 114,
    volume: 4,
    isManuallyDisabled: false,
    maxVolume: 5,
    warningVolume: 3,
    minVolume: 4,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    motorIds: [302, 303],
    rowNumber: 3,
    motorNumber: 302,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 15,
    cellNumber: 115,
    volume: 1,
    isManuallyDisabled: true,
    maxVolume: 3,
    warningVolume: 3,
    minVolume: 1,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    motorIds: [304],
    rowNumber: 3,
    motorNumber: 304,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 16,
    cellNumber: 116,
    volume: 5,
    isManuallyDisabled: false,
    maxVolume: 6,
    warningVolume: 3,
    minVolume: 5,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    motorIds: [305],
    rowNumber: 3,
    motorNumber: 305,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 17,
    cellNumber: 117,
    volume: 2,
    isManuallyDisabled: false,
    maxVolume: 3,
    warningVolume: 3,
    minVolume: 1,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    motorIds: [306, 307],
    rowNumber: 3,
    motorNumber: 306,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 18,
    cellNumber: 118,
    volume: 0,
    isManuallyDisabled: false,
    maxVolume: 2,
    warningVolume: 3,
    minVolume: 0,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    motorIds: [308],
    rowNumber: 3,
    motorNumber: 308,
  },*/

  // 4 row
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 19,
    cellNumber: 119,
    volume: 4,
    isManuallyDisabled: false,
    maxVolume: 5,
    warningVolume: 3,
    minVolume: 3,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [401, 402],
    rowNumber: 4,
    motorNumber: 401,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 20,
    cellNumber: 120,
    volume: 5,
    isManuallyDisabled: false,
    maxVolume: 5,
    warningVolume: 3,
    minVolume: 5,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [403],
    rowNumber: 4,
    motorNumber: 403,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 21,
    cellNumber: 121,
    volume: 3,
    isManuallyDisabled: false,
    maxVolume: 4,
    warningVolume: 3,
    minVolume: 1,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [404],
    rowNumber: 4,
    motorNumber: 404,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 22,
    cellNumber: 122,
    volume: 2,
    isManuallyDisabled: true,
    maxVolume: 3,
    warningVolume: 3,
    minVolume: 1,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [405, 406],
    rowNumber: 4,
    motorNumber: 406,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 23,
    cellNumber: 123,
    volume: 6,
    isManuallyDisabled: false,
    maxVolume: 6,
    warningVolume: 3,
    minVolume: 6,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [407],
    rowNumber: 4,
    motorNumber: 407,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 24,
    cellNumber: 124,
    volume: 1,
    isManuallyDisabled: false,
    maxVolume: 3,
    warningVolume: 3,
    minVolume: 1,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [408],
    rowNumber: 4,
    motorNumber: 408,
    expirationTimer: null,
  },
  // 5 row
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 25,
    cellNumber: 125,
    volume: 5,
    isManuallyDisabled: false,
    maxVolume: 5,
    warningVolume: 3,
    minVolume: 5,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [501],
    rowNumber: 5,
    motorNumber: 501,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 26,
    cellNumber: 126,
    volume: 3,
    isManuallyDisabled: false,
    maxVolume: 4,
    warningVolume: 3,
    minVolume: 2,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [502],
    rowNumber: 5,
    motorNumber: 502,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 27,
    cellNumber: 127,
    volume: 2,
    isManuallyDisabled: true,
    maxVolume: 3,
    warningVolume: 3,
    minVolume: 1,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [503, 504],
    rowNumber: 5,
    motorNumber: 503,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 28,
    cellNumber: 128,
    volume: 1,
    isManuallyDisabled: false,
    maxVolume: 1,
    warningVolume: 3,
    minVolume: 0,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [505],
    rowNumber: 5,
    motorNumber: 505,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 29,
    cellNumber: 129,
    volume: 4,
    isManuallyDisabled: false,
    maxVolume: 6,
    warningVolume: 3,
    minVolume: 3,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [506],
    rowNumber: 5,
    motorNumber: 506,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 30,
    cellNumber: 130,
    volume: 0,
    isManuallyDisabled: false,
    maxVolume: 2,
    warningVolume: 3,
    minVolume: 0,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [507],
    rowNumber: 5,
    motorNumber: 507,
    expirationTimer: null,
  },

  // 6 row
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 31,
    cellNumber: 131,
    volume: 2,
    isManuallyDisabled: false,
    maxVolume: 5,
    warningVolume: 3,
    minVolume: 1,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [601],
    rowNumber: 6,
    motorNumber: 601,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 32,
    cellNumber: 132,
    volume: 5,
    isManuallyDisabled: false,
    maxVolume: 5,
    warningVolume: 3,
    minVolume: 5,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [602],
    rowNumber: 6,
    motorNumber: 602,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 33,
    cellNumber: 133,
    volume: 3,
    isManuallyDisabled: false,
    maxVolume: 3,
    warningVolume: 3,
    minVolume: 3,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [603],
    rowNumber: 6,
    motorNumber: 603,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 34,
    cellNumber: 134,
    volume: 4,
    isManuallyDisabled: false,
    maxVolume: 4,
    warningVolume: 5,
    minVolume: 4,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [604],
    rowNumber: 6,
    motorNumber: 604,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 35,
    cellNumber: 135,
    volume: 1,
    isManuallyDisabled: true,
    maxVolume: 2,
    warningVolume: 3,
    minVolume: 1,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [605],
    rowNumber: 6,
    motorNumber: 605,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 36,
    cellNumber: 136,
    volume: 5,
    isManuallyDisabled: false,
    maxVolume: 5,
    warningVolume: 3,
    minVolume: 5,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [606],
    rowNumber: 6,
    motorNumber: 606,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 37,
    cellNumber: 137,
    volume: 0,
    isManuallyDisabled: false,
    maxVolume: 2,
    warningVolume: 3,
    minVolume: 0,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [607],
    rowNumber: 6,
    motorNumber: 607,
    expirationTimer: null,
  },

  // 7 row
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 31,
    cellNumber: 131,
    volume: 2,
    isManuallyDisabled: false,
    maxVolume: 5,
    warningVolume: 3,
    minVolume: 1,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [701],
    rowNumber: 7,
    motorNumber: 701,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 32,
    cellNumber: 132,
    volume: 5,
    isManuallyDisabled: false,
    maxVolume: 5,
    warningVolume: 3,
    minVolume: 5,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [702],
    rowNumber: 7,
    motorNumber: 702,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 33,
    cellNumber: 133,
    volume: 3,
    isManuallyDisabled: false,
    maxVolume: 3,
    warningVolume: 3,
    minVolume: 3,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [703],
    rowNumber: 7,
    motorNumber: 703,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 34,
    cellNumber: 134,
    volume: 4,
    isManuallyDisabled: false,
    maxVolume: 4,
    warningVolume: 5,
    minVolume: 4,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [704],
    rowNumber: 7,
    motorNumber: 704,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 35,
    cellNumber: 135,
    volume: 1,
    isManuallyDisabled: true,
    maxVolume: 2,
    warningVolume: 3,
    minVolume: 1,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [705],
    rowNumber: 7,
    motorNumber: 705,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 36,
    cellNumber: 136,
    volume: 5,
    isManuallyDisabled: false,
    maxVolume: 5,
    warningVolume: 3,
    minVolume: 5,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [706],
    rowNumber: 7,
    motorNumber: 706,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 37,
    cellNumber: 137,
    volume: 0,
    isManuallyDisabled: false,
    maxVolume: 2,
    warningVolume: 3,
    minVolume: 0,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [707],
    rowNumber: 7,
    motorNumber: 707,
    expirationTimer: null,
  },
  {
    snackBrandName: null,
    snackProductName: null,
    snackBrandId: null,
    snackProductId: null,
    id: 38,
    cellNumber: 138,
    volume: 3,
    isManuallyDisabled: false,
    maxVolume: 5,
    warningVolume: 3,
    minVolume: 2,
    wasExtraTwist: false,
    didNotGiveOutTheGood: false,
    isDisabledDueToMerge: false,
    motorIds: [708],
    rowNumber: 7,
    motorNumber: 708,
    expirationTimer: null,
  },
];

export const getSnackCellsByMachineHome = (): MachineHomeSnackCell[] =>
  snackCells.map(
    ({
      id,
      maxVolume,
      minVolume,
      volume,
      isManuallyDisabled,
      rowNumber,
      motorIds,
      warningVolume,
      cellNumber,
    }) =>
      ({
        id,
        cellNumber,
        warningVolume,
        maxVolume,
        minVolume,
        volume,
        isManuallyDisabled,
        rowNumber,
        motorIds,
      }) as MachineHomeSnackCell,
  );

export const getSnackCellsByMachineList = (): MachineSnackCell[] =>
  snackCells.map(
    ({
      id,
      maxVolume,
      minVolume,
      volume,
      isManuallyDisabled,
      rowNumber,
      warningVolume,
      motorIds,
      isDisabledDueToMerge,
    }) => ({
      id,
      warningVolume,
      maxVolume,
      minVolume,
      volume,
      isManuallyDisabled,
      rowNumber,
      motorIds,
      isDisabledDueToMerge,
    }),
  );

export const getSnackCellsByMachineStorageInfo = (): MachineStorageInfoSnackCell[] =>
  snackCells.map(
    ({
      snackProductName,
      snackProductId,
      snackBrandName,
      snackBrandId,
      motorNumber,
      cellNumber,
      id,
      maxVolume,
      minVolume,
      volume,
      isManuallyDisabled,
      wasExtraTwist,
      didNotGiveOutTheGood,
      rowNumber,
      warningVolume,
      motorIds,
      isDisabledDueToMerge,
    }) => ({
      snackProductId,
      snackProductName,
      snackBrandId,
      motorNumber,
      cellNumber,
      snackBrandName,
      id,
      warningVolume,
      maxVolume,
      minVolume,
      volume,
      isManuallyDisabled,
      wasExtraTwist,
      didNotGiveOutTheGood,
      rowNumber,
      motorIds,
      isDisabledDueToMerge,
      expirationTimer: null,
    }),
  );
